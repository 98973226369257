import { FC, ReactNode, useRef, useState } from 'react';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import { ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import 'react-image-gallery/styles/css/image-gallery.css';

import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { isMobileDevice } from 'environment';
import { IconButton } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';

import AsideDeviceTopPanel from 'components/layout/aside-device/header';
import { Icon } from 'components/shared';
import { ColorCircle } from 'components/shared/product/ColorCircle';
import { IAttribute, IProductAttributes } from 'models';
import { watchProductAsync } from 'store/catalog/actions';

import { StyledIcon } from './index';
import styles from './styles';

interface IProps {
  classes?: any;
  images: string[];
  videos: string[];
  modalOpen: boolean;
  toggleModalGallery: () => void;
  title: string;
  productId: string;
  isWatch?: boolean;
  attributes?: IProductAttributes;
  attributesList?: IAttribute[];
}

interface IZoomImageProps {
  item: ReactImageGalleryItem;
  classes: any;
  disableSwipe: boolean;
  setDisableSwipe: (state: boolean) => void;
  currentIndex: number;
  setStartIndex: (state: number) => void;
}

const isMobile = isMobileDevice(window.navigator);

const parseYouTubeId = (url: string) => {
  const parsedUrl = new URL(url);
  let videoId: string | null = null;

  if (parsedUrl.pathname === '/watch') {
    videoId = parsedUrl.searchParams.get('v');
  } else if (parsedUrl.pathname.startsWith('/shorts/')) {
    videoId = parsedUrl.pathname.split('/shorts/')[1].split('?')[0];
  }

  return videoId;
};

const ZoomImage = withStyles<any>(styles)(({
  item,
  classes,
  disableSwipe,
  setDisableSwipe,
  currentIndex,
  setStartIndex
}: IZoomImageProps) => {
  const [scale, setScale] = useState(1);

  const onZoomChange = (ref: ReactZoomPanPinchRef) => {
    const refScale = ref.state.scale;
    if (refScale > 1) {
      setScale(refScale);
      if (!disableSwipe) {
        setDisableSwipe(true);
      }
      setStartIndex(currentIndex);
    } else {
      setScale(1);
      if (disableSwipe) {
        setDisableSwipe(false);
      }
      setStartIndex(currentIndex);
    }
  };

  return (
    <TransformWrapper
      onZoom={onZoomChange}
      minScale={1}
      centerOnInit
      panning={{ disabled: scale <= 1 }}
      doubleClick={{ disabled: scale <= 1 }}
    >
      <TransformComponent wrapperStyle={{ width: 'auto', height: 'auto' }}>
        <img src={item.original} alt={''} className={classes.mainSliderImg} />
      </TransformComponent>
    </TransformWrapper>
  );
});

const getVideoThumbnail = (videoId: string | null) => {
  return `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;
};

const MiniDetailsModalGallery: FC<IProps> = ({
  classes,
  images,
  modalOpen,
  toggleModalGallery,
  videos,
  title,
  isWatch,
  productId,
  attributes,
  attributesList
}) => {
  const [disableSwipe, setDisableSwipe] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const dispatch = useDispatch();
  const getImageUrl = (img: string): string =>
    img ? `${process.env.REACT_APP_API_ENDPOINT}/static/product/image/${img}` : '/images/flower-no-image.svg';

  const currentIndex = useRef(0);

  function handleClickOnMobile(e: any) {
    if (
      e.target &&
      !e.target.closest('[data-navigation]') &&
      !e.target.classList.contains('MuiPaper-root') &&
      (isMobile || !e.target.classList.contains('MuiDialogContent-root')) &&
      !e.target.classList.contains('image-gallery-slide') &&
      !e.target.classList.contains('react-transform-component') &&
      !e.target.classList.contains('react-transform-wrapper')
    ) {
      toggleModalGallery();
    }
  }

  const renderImage = (item: ReactImageGalleryItem) => {
    return (
      <ZoomImage
        item={item}
        setDisableSwipe={setDisableSwipe}
        setStartIndex={setStartIndex}
        disableSwipe={disableSwipe}
        currentIndex={currentIndex.current}
      />
    );
  };

  const renderVideo = (videoId: string | null) => {
    if (!videoId) {
      return null;
    }

    const embedUrl = `https://www.youtube.com/embed/${videoId}`;
    return (
      <iframe
        className={classes.video}
        src={embedUrl}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        data-navigation="true"
        style={{ border: '0px' }}
      />
    );
  };

  const videoArray: ReactImageGalleryItem[] = videos?.map((videoUrl) => {
    const videoId = parseYouTubeId(videoUrl);

    return {
      original: videoUrl,
      thumbnail: getVideoThumbnail(videoId),
      renderItem: () => renderVideo(videoId),
      thumbnailWidth: 104,
      renderThumbInner(item: ReactImageGalleryItem): ReactNode {
        return (
          <span>
          <Icon type="play" size={24} className={classes.videoPlayIcon} opacity={1} />
          <img src={item?.thumbnail} alt={videoUrl} className={classes.thumbnail} />
        </span>
        );
      },
      thumbnailClass: classes.thumbnail,
    };
  });

  const imagesArray: ReactImageGalleryItem[] = images.map((imgId) => ({
    original: getImageUrl(imgId),
    thumbnail: getImageUrl(imgId),
    renderItem: renderImage,
    thumbnailWidth: 104,
    thumbnailClass: classes.thumbnail
  }));

  const mediaArray = videoArray?.length ? [...videoArray, ...imagesArray] : imagesArray;

  const onBeforeSlide = (idx: number) => {
    currentIndex.current = idx;
  };

  return (
    <Dialog
      open={modalOpen}
      onClick={handleClickOnMobile}
      onClose={toggleModalGallery}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      classes={{
        root: classes.dialogRoot,
        container: classes.dialogContainer,
        scrollBody: classes.scrollBody,
        paperWidthSm: classes.paperWidthSm,
        paperScrollBody: classes.galleryScrollBody
      }}
    >
      <DialogTitle className={classes.dialogTitle}>
        <AsideDeviceTopPanel
          title={title}
          onClose={toggleModalGallery}
          action={
            <IconButton
              onClick={() => dispatch(watchProductAsync.request({ productId, watch: !isWatch }))}
              size="large"
            >
              <StyledIcon
                type={isWatch ? 'heartFull' : 'heart'}
                size={24}
                className={clsx('favourite', { selected: isWatch })}
              />
            </IconButton>
          }
        />
      </DialogTitle>
      <DialogContent className={classes.galleryContent}>
        <div className={classes.wrapper}>
          <Icon type="cross" size={12} className={classes.closeIcon} onClick={toggleModalGallery} />
          <span className={classes.title}>{title}</span>
          <div className={classes.attrContainer}>
            <ColorCircle color={attributes?.color?.value || ''} className={classes.colorCircle} />
            {attributesList?.map((attr) => (
              <div key={attr.code} className={classes.attributeContainer}>
                <Icon size={16} type={attr.alias} className={classes.icon} opacity={1} />
                <p className={classes.attributeName}>{attr.value}</p>
              </div>
            ))}
          </div>
          <ImageGallery
            disableSwipe={disableSwipe}
            startIndex={startIndex}
            autoPlay={false}
            showFullscreenButton={false}
            showPlayButton={false}
            items={mediaArray}
            onBeforeSlide={onBeforeSlide}
            additionalClass={clsx(classes.imageGallery, { [classes.wrapperImage]: mediaArray?.length === 1 })}
            infinite={false}
            thumbnailPosition={isMobile ? 'bottom' : 'right'}
            showIndex={mediaArray?.length > 1}
            indexSeparator={' з '}
            renderLeftNav={(onClick, disabled) => (
              <button onClick={onClick} disabled={disabled} className={classes.leftNav}>
                <Icon
                  type="arrowPagination"
                  size={20}
                  opacity={1}
                  className={clsx(classes.arrow, { [classes.disabledArrow]: disabled })}
                />
              </button>
            )}
            renderRightNav={(onClick, disabled) => (
              <button onClick={onClick} disabled={disabled} className={classes.rightNav}>
                <Icon
                  type="arrowPagination"
                  size={20}
                  opacity={1}
                  className={clsx(classes.arrow, { [classes.disabledArrow]: disabled })}
                />
              </button>
            )}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles<any>(styles)(MiniDetailsModalGallery);
