import React, { FC } from 'react';
import clsx from 'clsx';
import { Checkbox, FormControlLabel, IconButton, Menu, MenuItem } from '@mui/material';

import Icon from '../Icon';

import classes from './SelectMenu.module.scss';

interface IOption {
  name: string;
  alias: string;
  itemsQty?: number;
}

interface ISelectMenuProps {
  label: string;
  options: IOption[];
  activeItems: string[];
  allSelected: boolean;
  anchorEl: HTMLElement | null;
  openMenu: boolean;
  handleOpenMenu: (e: React.MouseEvent<HTMLElement>) => void;
  handleCloseMenu: () => void;
  handleMenuItemClick: (alias: string, checked: boolean) => void;
  activeLabels?: string;
  withoutQty?: boolean;
}

const SelectMenu: FC<ISelectMenuProps> = ({
  label,
  options,
  activeItems,
  allSelected,
  anchorEl,
  openMenu,
  handleOpenMenu,
  handleCloseMenu,
  handleMenuItemClick,
  activeLabels,
  withoutQty
}) => {
  return (
    <div className={classes.tableToolbarContainer}>
      <IconButton onClick={handleOpenMenu} className={classes.button}>
        <span className={classes.label}>{label}:</span>&nbsp;
        <span className={classes.selectedLabel}>{activeLabels}</span>
        <Icon
          type="arrow"
          size={12}
          leftOffset={8}
          className={clsx(classes.arrow, { [classes.arrowRotate]: openMenu })}
        />
      </IconButton>
      <Menu anchorEl={anchorEl} open={openMenu} onClose={handleCloseMenu}>
        {options.map((tabItem) => {
          return (
            <MenuItem key={tabItem.alias} disabled={!withoutQty && !Boolean(tabItem.itemsQty)}>
              <FormControlLabel
                classes={{ root: classes.formControl }}
                control={
                  <Checkbox
                    checked={
                      tabItem.alias === 'all' ? allSelected : !allSelected && activeItems.includes(tabItem.alias)
                    }
                    onChange={(event) => handleMenuItemClick(tabItem.alias, event.target.checked)}
                  />
                }
                label={
                  withoutQty
                    ? tabItem.name
                    : `${tabItem.name} (${tabItem.itemsQty ? tabItem.itemsQty : 0})`
                }
              />
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export { SelectMenu };
