import React from 'react';
import { Button, Grid, Hidden } from '@mui/material';
import { withStyles } from '@mui/styles';

import { SplitedPrice } from 'components/prices';
import { TextBody2 } from 'components/shared/text';
import messages from 'translations/cart/common';

import styles from './styles';

interface IProps {
  isAvailable?: boolean;
  hasPreOrder?: boolean;
  itemsNumber: number;
  discount: number;
  amount: number;
  classes?: any;
  onRemove?: () => void;
}

const CartGroupCardSubtotal: React.FC<IProps> = ({
  isAvailable,
  hasPreOrder,
  itemsNumber,
  discount,
  amount,
  classes,
  children,
  onRemove
}) => {
  const discountBlock = (
    <div className={classes.discountItem}>
      <TextBody2 color={'inherit'} align={'right'}>
        {messages.totalDiscount.defaultMessage}
      </TextBody2>
      <SplitedPrice fontColor={'inherit'} fontSize={'h3'} value={discount} className={classes.discountPrice} />
    </div>
  );

  return (
    <div className={classes.subtotalWrapper}>
      <Hidden smDown>
        <Grid item sm={'auto'}>
          <Grid container alignItems={'flex-end'} justifyContent={'flex-end'}>
            <Grid item sm={'auto'}>
              {onRemove && (
                <Button color={'primary'} onClick={onRemove}>
                  {messages.removeFromCart.defaultMessage}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
      {isAvailable && (
        <>
          <Grid item container sm justifyContent={'flex-end'} className={classes.gray}>
            <div className={classes.totalQtyItem}>
              <TextBody2 color={'inherit'} align={'right'}>
                {messages.totalQty.defaultMessage}
              </TextBody2>
              <SplitedPrice
                fontColor={'inherit'}
                fontSize={'subtitle1'}
                value={itemsNumber}
                postfix={'шт'}
                hideDecimal
                className={classes.quantityValue}
              />
            </div>
            <>
              <Hidden smUp>{discountBlock}</Hidden>
              {discount > 0 && <Hidden smDown>{discountBlock}</Hidden>}
            </>
            <div className={classes.sumPriceItem}>
              <TextBody2 color={'inherit'} align={'right'}>
                {messages.totalSum.defaultMessage}
              </TextBody2>
              <SplitedPrice
                fontColor={'inherit'}
                fontSize={'h3'}
                fontSizeDecimal={'body2'}
                value={amount}
                prefix={hasPreOrder ? '~' : undefined}
                className={classes.sumValue}
              />
            </div>
          </Grid>
          <Grid item sm={'auto'} className={classes.submitButton}>
            {children}
          </Grid>
        </>
      )}
    </div>
  );
};

export default withStyles<any>(styles)(CartGroupCardSubtotal);
