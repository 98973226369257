import { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import { DEFAULT_AMOUNT } from 'components/cards/shared/BalanceCard';
import { FlrButtonKhaki } from 'components/shared/buttons';
import FlrCard from 'components/shared/card/FlrCard';
import PaymentModal from 'components/shared/modals/payment/PaymentModal';
import { Link2 } from 'components/shared/text';
import { IBalance } from 'models/account-discounts';
import { baseUrl, defaultCatalogProductType } from 'shared/constants';
import { getUserAccount } from 'store/account/selectors';
import messages from 'translations/account/dashboard';
import { formatNumber } from 'utils/helpers';

import classes from './DashboardLoyaltyProgramCard.module.scss';

interface IDashboardLoyaltyProgramCardProps {
  balance?: IBalance;
  title?: string;
  linkTitle?: string;
  description?: string;
  percent?: number;
  hasLink?: boolean;
}
const DashboardLoyaltyProgramCard: FC<IDashboardLoyaltyProgramCardProps> = ({
  balance,
  percent,
  linkTitle,
  title,
  description,
  hasLink
}) => {
  const account = useSelector(getUserAccount);
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);

  const handlePayCancel = () => {
    setPaymentModalOpen(false);
  };
  const handleOpenPayModal = () => {
    setPaymentModalOpen(true);
  };
  const creditUsed = account && account.profile.actualBalance ? account.profile.actualBalance : DEFAULT_AMOUNT;

  return (
    <>
      <FlrCard hoverShadow className={classes.card}>
        <div className={classes.titleContainer}>
          <h4 className={classes.title} title={title}>
            {title || 'Знижка за депозит'}
          </h4>
          <Link2
            to={hasLink ? `${baseUrl}/catalog/${defaultCatalogProductType}` : `${baseUrl}/account/loyalty`}
            classes={{ root: classes.link }}
          >
            {linkTitle || 'Детальніше'}
          </Link2>
        </div>
        <div className={classes.wrapper}>
          <div className={classes.item}>
            <p className={classes.nextDiscountLabel}>
              {description || (
                <>
                  {messages.nextDiscount.defaultMessage}&nbsp;{balance?.nextDiscountProgress.percent}%:&nbsp;
                  {formatNumber(Number(balance?.nextDiscountProgress.requiredValue))}
                </>
              )}
            </p>
            {balance && (
              <FlrButtonKhaki variant="outlined" color="primary" fullWidth onClick={handleOpenPayModal}>
                Поповнити
              </FlrButtonKhaki>
            )}
          </div>
          <div className={classes.hr} />
          <div className={classes.item}>
            <span className={classes.discountLabel}>Знижка</span>
            <span className={classes.discountValue}>{percent || balance?.currentDiscount.percent}%</span>
          </div>
        </div>
      </FlrCard>
      {paymentModalOpen && <PaymentModal open={paymentModalOpen} handleClose={handlePayCancel} amount={creditUsed} />}
    </>
  );
};

export { DashboardLoyaltyProgramCard };
