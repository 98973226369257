import React from 'react';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { debounce } from 'lodash';
import { Button, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';

import CartGroupCardDevice from 'components/cards/cart/device/CartGroupCardDevice';
import ProductDetailsMobile from 'components/cards/shared/ProductDetailsMobile';
import PaymentModal from 'components/shared/modals/payment/PaymentModal';
import IconCartTimer from 'components/timer/IconCartTimer';
import { Cart, CartItem, Product, UserProfile } from 'models';
import { fetchAccountAsync } from 'store/account/actions';
import { getUserProfile } from 'store/account/selectors';
import {
  addCartItemAsync,
  redirectToCatalog,
  removeCartItemAsync,
  selectProductAsync,
  setActiveItem,
  toggleProductInfo,
  unselectProduct
} from 'store/cart/actions';
import {
  getCartActiveItem,
  getCartMobileTab,
  getExpiredCartLayoutExpanded,
  getSelectedProductLoaded,
  getSelectedProductLoadingState
} from 'store/cart/selectors';
import { ISelectedProduct } from 'store/catalog/actions';
import { renewCheckoutAsync } from 'store/checkout/actions';
import {
  fetchExpiredCartAsync,
  removeExpiredCartAsync,
  removeExpiredCartGroupAsync,
  removeExpiredCartItemAsync,
  restoreExpiredCartAsync,
  updateExpiredCartItemAsync
} from 'store/expired-cart/actions';
import { IApplicationState } from 'store/reducers';

import styles from '../styles';

interface IProps {
  classes: any;
  anchor?: string;
  cart: Cart;
  cartLoaded: boolean;
  cartLoadingState: boolean;
  expiredCart: Cart;
  expiredCartLoaded: boolean;
  expiredCartLoadingState: boolean;
  selectedProduct: ISelectedProduct;
}

interface IStateProps {
  locale: any;
  userProfile: UserProfile | undefined;
  selectedProductLoaded: boolean;
  selectedProductLoadingState: boolean;
  activeCartItem: number;
  mobileCartTab: number;
  expiredCartExpanded: boolean;
}

interface IDispatchProps {
  selectProduct: typeof selectProductAsync.request;
  deselectProduct: typeof unselectProduct;
  checkoutCart: typeof renewCheckoutAsync.request;
  restoreExpiredCart: typeof restoreExpiredCartAsync.request;
  addCartItem: typeof addCartItemAsync.request;
  removeCartItem: typeof removeCartItemAsync.request;
  loadExpiredCart: typeof fetchExpiredCartAsync.request;
  updateExpiredCartItem: typeof updateExpiredCartItemAsync.request;
  removeExpiredCart: typeof removeExpiredCartAsync.request;
  removeExpiredCartItem: typeof removeExpiredCartItemAsync.request;
  removeExpiredCartGroup: typeof removeExpiredCartGroupAsync.request;
  catalogRedirect: typeof redirectToCatalog;
  setActiveCartItem: typeof setActiveItem;
  toggleProductInfo: typeof toggleProductInfo;
  loadAccountData: typeof fetchAccountAsync.request;
}

type CartProps = IProps & IStateProps & IDispatchProps;

const UnconnectedMainCart: React.FC<CartProps> = ({
  anchor,
  userProfile,
  cart,
  expiredCart,
  selectedProduct,
  deselectProduct,
  selectProduct,
  checkoutCart,
  restoreExpiredCart,
  removeExpiredCart,
  removeExpiredCartItem,
  addCartItem,
  removeCartItem,
  updateExpiredCartItem,
  mobileCartTab,
  classes,
  loadAccountData
}) => {
  const [modalPayOpen, setModalPayOpen] = React.useState(false);

  const handlePayCancel = () => {
    setModalPayOpen(false);
  };
  const handleOpenPayModal = () => {
    setModalPayOpen(true);
  };

  const handleExpiredCartRemove = () => {
    removeExpiredCart();
  };

  const actualBalance = (userProfile && userProfile.actualBalance) || 0;
  const availableFunds = Number(userProfile?.availableFunds) || 0;
  const [mobileProduct, setMobileProductInfo] = React.useState<Product | null>(null);

  const onMoreInfo = (product: Product) => {
    setMobileProductInfo(product);
  };

  const onSuccess = () => {
    if (loadAccountData) {
      loadAccountData();
    }
  };

  const debouncedQtyUpdate = debounce(addCartItem, 300);

  const commonProps = {
    anchor: `${anchor}`,
    addBalance: handleOpenPayModal,
    balance: availableFunds,
    loadAccountData,
    selectedProductId: (selectedProduct && selectedProduct.id) || '',
    onRemoveItem: (item: CartItem) => removeCartItem(item.toRequestItem()),
    onQtyUpdate: (item: CartItem) => debouncedQtyUpdate(item.toRequestItem()),
    onSelectItem: (item: CartItem) => {
      if (!item.product.id) {
        item.product.id = item.offer.productId;
      }
      return selectProduct(item.product);
    },
    onUnselectItem: () => deselectProduct(),
    onMoreInfo
  };

  const debouncedQtyUpdateExpired = debounce(updateExpiredCartItem, 300);

  return (
    <>
      <Grid container className={classes.deviceTimer}>
        <Grid item xs={'auto'}>
          {cart && (cart.regularGroups.length > 0 || cart.inTransitGroups.length > 0) && (
            <IconCartTimer
              bold
              type={mobileCartTab === 1 ? 'transit' : 'inStock'}
              preOrder={mobileCartTab === 2}
              expired={mobileCartTab === 3}
            />
          )}
        </Grid>
      </Grid>
      {cart && cart.regularGroups.length > 0 && (
        <div hidden={mobileCartTab !== 0}>
          {cart.regularGroups.map((groupName: string, index: number) => (
            <CartGroupCardDevice
              key={`cart-group-card-${groupName}`}
              cartGroupName={groupName}
              cartGroup={cart.groups[groupName]}
              onSubmit={() => checkoutCart(groupName)}
              {...commonProps}
            />
          ))}
        </div>
      )}
      {cart && cart.inTransitGroups.length > 0 && (
        <div hidden={mobileCartTab !== 1}>
          {cart.inTransitGroups.map((groupName: string, index: number) => (
            <CartGroupCardDevice
              key={`cart-group-card-${groupName}`}
              cartGroupName={groupName}
              cartGroup={cart.groups[groupName]}
              onSubmit={() => checkoutCart(groupName)}
              {...commonProps}
            />
          ))}
        </div>
      )}
      {cart && cart.preOrderGroups.length > 0 && (
        <div hidden={mobileCartTab !== 2}>
          {cart.preOrderGroups.map((groupName: string, index: number) => (
            <CartGroupCardDevice
              preOrder
              key={`cart-group-card-${groupName}`}
              cartGroupName={groupName}
              cartGroup={cart.groups[groupName]}
              onSubmit={() => checkoutCart(groupName)}
              {...commonProps}
            />
          ))}
        </div>
      )}
      {expiredCart && expiredCart.items.length > 0 && (
        <div hidden={mobileCartTab !== 3}>
          {Object.keys(expiredCart.groups).map((groupName: string, index: number) => (
            <CartGroupCardDevice
              expired
              key={`expired-cart-group-card-${groupName}`}
              cartGroupName={groupName}
              cartGroup={expiredCart.groups[groupName]}
              onSubmit={() => restoreExpiredCart(groupName)}
              {...commonProps}
              onRemoveItem={(item: CartItem) => removeExpiredCartItem(item.toRequestItem())}
              onQtyUpdate={(item: CartItem) => debouncedQtyUpdateExpired(item.toRequestItem())}
            />
          ))}
          <Button onClick={handleExpiredCartRemove} style={{ display: 'none' }}>
            DELETE
          </Button>
        </div>
      )}
      {modalPayOpen && (
        <PaymentModal open={modalPayOpen} handleClose={handlePayCancel} amount={actualBalance} onSuccess={onSuccess} />
      )}
      <ProductDetailsMobile onClose={() => setMobileProductInfo(null)} product={mobileProduct} />
    </>
  );
};

const mapStateToProps: MapStateToProps<IStateProps, {}, IApplicationState> = (state: IApplicationState) => ({
  locale: state.locale,
  userProfile: getUserProfile(state),
  selectedProductLoaded: getSelectedProductLoaded(state),
  selectedProductLoadingState: getSelectedProductLoadingState(state),
  activeCartItem: getCartActiveItem(state),
  expiredCartExpanded: getExpiredCartLayoutExpanded(state),
  mobileCartTab: getCartMobileTab(state)
});

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, {}> = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      selectProduct: selectProductAsync.request,
      deselectProduct: unselectProduct,
      addCartItem: addCartItemAsync.request,
      removeCartItem: removeCartItemAsync.request,
      loadExpiredCart: fetchExpiredCartAsync.request,
      checkoutCart: renewCheckoutAsync.request,
      restoreExpiredCart: restoreExpiredCartAsync.request,
      updateExpiredCartItem: updateExpiredCartItemAsync.request,
      removeExpiredCartItem: removeExpiredCartItemAsync.request,
      removeExpiredCartGroup: removeExpiredCartGroupAsync.request,
      removeExpiredCart: removeExpiredCartAsync.request,
      catalogRedirect: redirectToCatalog,
      setActiveCartItem: setActiveItem,
      toggleProductInfo,
      loadAccountData: fetchAccountAsync.request
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles<any>(styles)(UnconnectedMainCart as any));
