import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'account.finance.title',
    description: '',
    defaultMessage: 'Фінанси'
  },
  balance: {
    id: 'account.finance.balance',
    description: '',
    defaultMessage: 'Баланс'
  },
  topUpBalance: {
    id: 'account.finance.topUpBalance',
    description: 'account.finance.topUpBalance',
    defaultMessage: 'Поповнити баланс'
  },
  topUp: {
    id: 'account.finance.topUp',
    description: '',
    defaultMessage: 'Поповнити'
  },
  topUpSave: {
    id: 'account.finance.topUpSave',
    description: '',
    defaultMessage: 'Вибрати'
  },
  balanceModalTitle: {
    id: 'account.finance.balanceModalTitle',
    description: '',
    defaultMessage: 'Поповнення балансу'
  },
  balanceModalText: {
    id: 'account.finance.balanceModalText',
    description: '',
    defaultMessage: 'На яку суму бажаєте поповнити рахунок?'
  },
  balanceModalRecommendedAmount: {
    id: 'account.finance.balanceModalRecommended',
    description: '',
    defaultMessage: 'Рекомендована сума'
  },
  balanceModalRecommendedAmountCapt: {
    id: 'account.finance.balanceModalRecommendedAmountCapt',
    description: '',
    defaultMessage: 'Погашення кредитної лінії'
  },
  balanceModalCreditAmount: {
    id: 'account.finance.balanceModalCreditAmount',
    description: '',
    defaultMessage: 'Мінімальна сума погашення'
  },
  balanceModalCreditAmountCapt: {
    id: 'account.finance.balanceModalCreditAmountCapt',
    description: '',
    defaultMessage: 'Погашення кредитної лінії'
  },
  balanceModalOther: {
    id: 'account.finance.balanceModalOther',
    description: '',
    defaultMessage: 'Інша сума'
  },
  balanceModalOtherLabel: {
    id: 'account.finance.balanceModalOtherLabel',
    description: '',
    defaultMessage: 'Вкажіть власну суму поповнення балансу'
  },
  balanceModalOtherPlaceholder: {
    id: 'account.finance.balanceModalOtherPlaceholder',
    description: '',
    defaultMessage: 'Вкажіть потрібну суму'
  },
  balanceModalCreditText: {
    id: 'account.finance.balanceModalCreditText',
    description: '',
    defaultMessage: 'Рахунок на оплату буде відправлено Вам менеджером'
  },
  balanceModalCashType: {
    id: 'account.finance.balanceModalCashType',
    description: '',
    defaultMessage: 'Готівка'
  },
  balanceModalLiqpayType: {
    id: 'account.finance.balanceModalLiqpayType',
    description: '',
    defaultMessage: 'Liqpay'
  },
  balanceModalCreditType: {
    id: 'account.finance.balanceModalCreditType',
    description: '',
    defaultMessage: 'Розрахунковий рахунок'
  },
  balanceModalPaymentType: {
    id: 'account.finance.balanceModalPaymentType',
    description: '',
    defaultMessage: 'Спосіб оплати'
  },
  creditLine: {
    id: 'account.finance.creditLine',
    description: '',
    defaultMessage: 'Кредитна лінія'
  },
  creditLineMyLimit: {
    id: 'account.finance.creditLineMyLimit',
    description: '',
    defaultMessage: 'Мій ліміт'
  },
  creditLineMyLimitOnce: {
    id: 'account.finance.creditLineMyLimitOnce',
    description: '',
    defaultMessage: 'разово'
  },
  creditLineUsed: {
    id: 'account.finance.creditLineUsed',
    description: '',
    defaultMessage: 'Використано'
  },
  creditLineAvailable: {
    id: 'account.finance.creditLineAvailable',
    description: '',
    defaultMessage: 'Доступно'
  },
  creditLineHistory: {
    id: 'account.finance.creditLineHistory',
    description: '',
    defaultMessage: 'Історія змін'
  },
  creditLineIncrease: {
    id: 'account.finance.creditLineIncrease',
    description: '',
    defaultMessage: 'Збільшити'
  },
  creditLineRequestSent: {
    id: 'account.finance.creditLineIncrease',
    description: '',
    defaultMessage: 'Запрос відіслано'
  },
  creditLineRequestErrorTitle: {
    id: 'account.finance.creditLineRequestErrorTitle',
    description: '',
    defaultMessage: 'Помилка'
  },
  creditLineRequestError: {
    id: 'account.finance.creditLineRequestError',
    description: '',
    defaultMessage: 'Виникла помилка, спробуйте пізнише'
  },
  creditLineRequestErrorAlready: {
    id: 'account.finance.creditLineRequestError',
    description: '',
    defaultMessage: 'Ви вже маєте кредит'
  },
  creditLineModalTitle: {
    id: 'account.finance.creditLineModalTitle',
    description: '',
    defaultMessage: 'Заявка на збільшення Кредитної лінії'
  },
  creditLineModalText: {
    id: 'account.finance.creditLineModalText',
    description: '',
    defaultMessage: 'Подайте заявку на збільшення Кредитної лінії, і з вами у продовж 15 хв зв’яжеться наш менеджер.'
  },
  creditLineInputLabel: {
    id: 'account.finance.creditLineInputLabel',
    description: '',
    defaultMessage: 'Бажана сума Кредитної лінії, ₴ *'
  },
  creditLineInputPlaceholder: {
    id: 'account.finance.creditLineInputPlaceholder',
    description: '',
    defaultMessage: 'Вкажіть потрібну суму'
  },
  creditLineInputHelpText: {
    id: 'account.finance.creditLineInputHelpText',
    description: '',
    defaultMessage: 'Моя Кредитна лінія зараз: '
  },
  creditLineOnceCheckbox: {
    id: 'account.finance.creditLineOnceCheckbox',
    description: '',
    defaultMessage: 'Збільшити одноразово (для одного замовлення)'
  },
  depositDiscount: {
    id: 'account.finance.addressTitle',
    description: '',
    defaultMessage: 'Знижка на депозит'
  },
  depositDiscountDetail: {
    id: 'account.finance.depositDiscountDetail',
    description: '',
    defaultMessage: 'Детальніше'
  },
  depositDiscountRefresh: {
    id: 'account.finance.depositDiscountRefresh',
    description: '',
    defaultMessage: ' о 00:01 оновлення знижки.'
  },
  depositDiscountReminder: {
    id: 'account.finance.depositDiscountReminder',
    description: '',
    defaultMessage: 'Не забудьте поповнити Баланс!'
  },
  downloadModalTitle: {
    id: 'account.finance.downloadModalTitle.type',
    description: '',
    defaultMessage: 'Завантажити Excel файл'
  },
  downloadModalButton: {
    id: 'account.finance.downloadModalButton.type',
    description: '',
    defaultMessage: 'Завантажити'
  },
  downloadModalCaption: {
    id: 'account.finance.downloadModalCaption.type',
    description: '',
    defaultMessage: 'Оберіть період за який вам потрібні транзакції'
  },
  downloadDateFrom: {
    id: 'account.finance.downloadDateFrom.type',
    description: 'account.finance.transaction.type',
    defaultMessage: 'Початок'
  },
  downloadDateTo: {
    id: 'account.finance.transaction.type',
    description: '',
    defaultMessage: 'Кінець'
  },
  type: {
    id: 'account.finance.transaction.type',
    description: 'account.finance.transaction.type',
    defaultMessage: 'Тип'
  },
  date: {
    id: 'account.finance.downloadDateFrom.date',
    description: '',
    defaultMessage: 'Дата'
  },
  amount: {
    id: 'account.finance.transaction.amount',
    description: '',
    defaultMessage: 'Сума, вал.'
  },
  amountBase: {
    id: 'account.finance.transaction.amountBase',
    description: '',
    defaultMessage: 'Сума'
  },
  tableBalance: {
    id: 'account.finance.transaction.amountBase',
    description: '',
    defaultMessage: 'Баланс'
  },
  transactionCurrency: {
    id: 'account.finance.transaction.transactionCurrency',
    description: '',
    defaultMessage: 'Курс, ₴'
  },
  balanceAfter: {
    id: 'account.finance.transaction.balanceAfter',
    description: '',
    defaultMessage: 'Баланс після транзакції, ₴'
  },
  transactionNumber: {
    id: 'account.finance.transaction.transactionNumber',
    description: '',
    defaultMessage: 'Номер'
  },
  filterAll: {
    id: 'account.transaction.filterAll',
    description: '',
    defaultMessage: 'Всі'
  },
  filterOrder: {
    id: 'account.transaction.filterOrder',
    description: '',
    defaultMessage: 'Замовлення'
  },
  filterPayment: {
    id: 'account.transaction.filterPayment',
    description: '',
    defaultMessage: 'Поповнення балансу'
  },
  filterReturn: {
    id: 'account.transaction.filterReturn',
    description: '',
    defaultMessage: 'Повернення коштів'
  },
  filterReclamation: {
    id: 'account.transaction.filterReclamation',
    description: '',
    defaultMessage: 'Рекламації'
  },
  emptyTable: {
    id: 'account.transaction.emptyTable',
    description: '',
    defaultMessage: 'Немає записів для відображення'
  },
  rows: {
    id: 'account.transaction.rows',
    description: '',
    defaultMessage: 'Pядків'
  },
  transaction: {
    id: 'account.transaction.addressTitle',
    description: '',
    defaultMessage: 'Транзакції'
  },
  lastTransactions: {
    id: 'account.transaction.lastTransaction',
    description: '',
    defaultMessage: 'Останні транзакції'
  },
  transactionDownloadXLS: {
    id: 'account.finance.transactionDownloadXLS',
    description: '',
    defaultMessage: 'Завантажити Excel файл'
  },
  fullName: {
    id: 'account.finance.fullName',
    description: 'account.finance.fullName',
    defaultMessage: 'ПІБ'
  },
  companyName: {
    id: 'account.finance.companyName',
    description: 'account.finance.companyName',
    defaultMessage: 'Назва Компанії'
  },
  address: {
    id: 'account.finance.fullName',
    description: 'account.finance.fullName',
    defaultMessage: 'Адреса'
  },
  destinationCity: {
    id: 'account.finance.city',
    description: 'account.finance.city',
    defaultMessage: 'Місто (для визначення терміну доставки)'
  },
  language: {
    id: 'account.finance.language',
    description: 'account.finance.language',
    defaultMessage: 'Мова спілкування з “Флоротека”'
  },
  currency: {
    id: 'account.finance.currency',
    description: 'account.finance.currency',
    defaultMessage: 'Валюта'
  },
  addOutletBtnLabel: {
    id: 'account.finance.outlet.addOutletBtnLabel',
    description: 'account.finance.outlet.addOutletBtnLabel',
    defaultMessage: 'Додати'
  },
  editBtnLabel: {
    id: 'account.finance.outlet.editBtnLabel',
    description: 'account.finance.outlet.editBtnLabel',
    defaultMessage: 'Редагувати'
  },
  saveBtnLabel: {
    id: 'account.finance.outlet.saveBtnLabel',
    description: 'account.finance.outlet.saveBtnLabel',
    defaultMessage: 'Зберегти'
  },
  cancelBtnLabel: {
    id: 'account.finance.outlet.cancelBtnLabel',
    description: 'account.finance.outlet.cancelBtnLabel',
    defaultMessage: 'Скасувати'
  },
  saveChangesConfirmTitle: {
    id: 'account.finance.outlet.saveChangesConfirmTitle',
    description: 'account.finance.outlet.saveChangesConfirmTitle',
    defaultMessage: 'Зберегти зміни?'
  },
  saveChangesConfirmText: {
    id: 'account.finance.outlet.continueChanges',
    description: 'account.finance.outlet.continueChanges',
    defaultMessage: `Ви маєте незбережені зміни в “{dataType}”.
    Продовжити без їх збереження?`
  },
  saveChangesConfirmContinue: {
    id: 'account.finance.outlet.continueChanges',
    description: 'account.finance.outlet.continueChanges',
    defaultMessage: 'Продовжити редагування'
  },
  titleFinance: {
    id: 'account.dashboard.titleFinance',
    description: '',
    defaultMessage: 'Фінанси'
  },
  history: {
    id: 'account.dashboard.history',
    description: '',
    defaultMessage: 'Історія'
  },
  operation: {
    id: 'account.dashboard.operation',
    description: '',
    defaultMessage: 'Операція'
  },
  factBalance: {
    id: 'account.dashboard.factBalance',
    description: '',
    defaultMessage: 'Фактичний'
  },
  totalBalance: {
    id: 'account.dashboard.totalBalance',
    description: '',
    defaultMessage: 'Загальний'
  },
  factBalanceValue: {
    id: 'account.dashboard.factBalanceValue',
    description: '',
    defaultMessage: 'Фактичний баланс'
  },
  totalBalanceValue: {
    id: 'account.dashboard.factBalanceValue',
    description: '',
    defaultMessage: 'Загальний баланс'
  },
  orderInPending: {
    id: 'account.dashboard.orderInPending',
    description: '',
    defaultMessage: 'Замовлення в обробці'
  },
  typeOrder: {
    id: 'account.dashboard.typeOrder',
    description: '',
    defaultMessage: 'Тип замовлення'
  },
  credit: {
    id: 'account.dashboard.credit',
    description: '',
    defaultMessage: 'Кредит'
  }
});
