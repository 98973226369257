import { defineMessages } from 'react-intl';

export default defineMessages({
  titleDelivery: {
    id: 'account.dashboard.titleDelivery',
    description: '',
    defaultMessage: 'Замовлення'
  },

  titleLoyalty: {
    id: 'account.dashboard.titleLoyalty',
    description: '',
    defaultMessage: 'Знижки за програмою лояльності'
  },
  subTitleLoyalty: {
    id: 'account.dashboard.subTitleLoyalty',
    description: '',
    defaultMessage: 'Знижки діють на товари від постачальника Флоротека. Знижки на один товар сумуються'
  },
  personalDiscount: {
    id: 'account.dashboard.title',
    description: '',
    defaultMessage: 'Всі товари Флоротека'
  },
  tooltipTitle: {
    id: 'account.dashboard.tooltipTitle',
    description: '',
    defaultMessage: 'Гарантована знижка з {nextMonth} - {guaranteedDiscountPercent}%.'
  },
  tooltipText: {
    id: 'account.dashboard.tooltipText',
    description: '',
    defaultMessage:
      'Можливо збільшити знижку до {nextDiscountPercent}%, за умови купівлі ще {nextDiscountAmount} шт товарів з групи до {lastDayOfMonth}'
  },
  currentDiscount: {
    id: 'account.dashboard.currentDiscount',
    description: '',
    defaultMessage: 'Діюча знижка'
  },
  nextDiscount: {
    id: 'account.dashboard.nextDiscount',
    description: '',
    defaultMessage: 'Депозит для наступної знижки в'
  },
  groupDiscount: {
    id: 'account.dashboard.groupDiscount',
    description: '',
    defaultMessage: 'Від кількості куплених товарів залежатиме відсоток знижки у наступному місяці.'
  },
  factBalance: {
    id: 'account.dashboard.factBalance',
    description: '',
    defaultMessage: 'Фактичний баланс'
  },
  factBalanceMobile: {
    id: 'account.dashboard.factBalance',
    description: '',
    defaultMessage: 'Фактичний'
  },
  title: {
    id: 'title.dashboard',
    description: '',
    defaultMessage: 'Інфопанель'
  },
  delivery: {
    id: 'account.dashboard.delivery',
    description: '',
    defaultMessage: 'Доставляється'
  },
  processed: {
    id: 'account.dashboard.processed',
    description: '',
    defaultMessage: 'Обробляється'
  },
  loyaltyTitleDesktop: {
    id: 'account.dashboard.loyaltyTitleDesktop',
    description: '',
    defaultMessage: 'Програма лояльності'
  },
  loyaltyTitleMobile: {
    id: 'account.dashboard.loyaltyTitleMobile',
    description: '',
    defaultMessage: 'Знижки за програмою лояльності'
  },
  totalBalance: {
    id: 'account.dashboard.totalBalance',
    description: '',
    defaultMessage: 'Загальний'
  },
  factBalanceValue: {
    id: 'account.dashboard.factBalanceValue',
    description: '',
    defaultMessage: 'Фактичний баланс'
  },
  totalBalanceValue: {
    id: 'account.dashboard.factBalanceValue',
    description: '',
    defaultMessage: 'Загальний баланс'
  },
  orderInPending: {
    id: 'account.dashboard.orderInPending',
    description: '',
    defaultMessage: 'Замовлення в обробці'
  },
  typeOrder: {
    id: 'account.dashboard.typeOrder',
    description: '',
    defaultMessage: 'Тип замовлення'
  },
  credit: {
    id: 'account.dashboard.credit',
    description: '',
    defaultMessage: 'Кредит'
  }
});
