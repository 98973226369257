import { HttpClient } from 'utils/http';
import { AuthService } from '../auth/service';
import { User } from 'models/user';
export class AccountRepository {
  private client = new HttpClient(`${process.env.REACT_APP_API_ENDPOINT}/account`);
  private service = new AuthService();

  public async fetch() {
    this.client.setAuthHeader();
    return await this.client.get('').catch(() => {
      this.service.authToken = null;
    });
  }

  public async update(data: User) {
    return await this.client.post('/profile', data);
  }

  public async repeatOrder(orderId: string) {
    return await this.client.get(`/orders/repeat/preorder/${orderId}`);
  }

  public async fetchRecommendedAmount() {
    return await this.client.get('/pay/recommended-amount');
  }
}
