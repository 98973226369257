import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { connect, MapStateToProps } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Redirect } from 'react-router-dom';
import clsx from 'clsx';
import { isMobileDevice } from 'environment';
import { Theme, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';

import { SelectMenu } from 'components/shared/select-menu/SelectMenu';
import { FlrTableV2 } from 'components/shared/table';
import { TitleH1 } from 'components/shared/text';
import { Order } from 'models';
import { baseUrl } from 'shared/constants';
import { getAccountLoadingState } from 'store/account/selectors';
import { IOrderStatusFilter, IOrderTypeFilter } from 'store/order/actions';
import { getOrdersFilters, getOrdersList, getOrdersLoadingState, getOrdersTotalDocs } from 'store/order/selectors';
import { IApplicationState } from 'store/reducers';
import messages from 'translations/account/order';

import { breadCrumbsContext } from '../breadCrumbsState';
import TableColumns, { columnsMobile, columnsTablet } from './AccountOrdersTableColumns';
import { AccountOrdersTableMobileRowExpanded } from './AccountOrdersTableMobileRowExpanded';

import classes from './AccountOrdersTable.module.scss';


interface IStateProps {
  accountLoadingState?: boolean;
  orders: Order[];
  ordersLoadingState: boolean;
  totalDocs: number;
  filters: {
    orderType: IOrderTypeFilter[];
    orderStatus: IOrderStatusFilter[];
  };
}

const rootPath = `/account/orders`;

const isMobile = Boolean(isMobileDevice(window.navigator));

const allOption = { name: 'Всі', alias: 'all' };

const calculateTotalItemsQty = (items: Array<{ itemsQty: number }>) =>
  items.reduce((sum, item) => sum + item.itemsQty, 0);

const addAllOption = (items: Array<{ name: string; alias: string; itemsQty: number }>, totalItemsQty: number) => [
  { ...allOption, itemsQty: totalItemsQty },
  ...items
];

const getActiveLabels = (
  activeItems: string[],
  allSelected: boolean,
  options: Array<{ name: string; alias: string }>,
  allName?: string
) => {
  return activeItems?.length > 0 && !allSelected
    ? options
        .map((item) => (activeItems.includes(item.alias) ? item.name : ''))
        .filter(Boolean)
        .join(', ')
    : allName;
};

const isAllSelected = (activeItems: string[], options: Array<{ alias: string }>) => {
  return activeItems.includes('all')
    ? activeItems.length > 0
    : options.filter((item) => item.alias !== 'all').every((item) => activeItems.includes(item.alias));
};

const AccountOrdersTable: FC<IStateProps> = ({ ordersLoadingState, orders, totalDocs, filters }) => {
  const history = useHistory();
  const location = useLocation();
  const theme: Theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.between(720, 1100));
  const [anchorStatusEl, setAnchorStatusEl] = useState<null | HTMLElement>(null);
  const [anchorTypeEl, setAnchorTypeEl] = useState<null | HTMLElement>(null);
  const [openStatusMenu, setOpenStatusMenu] = useState(false);
  const [openTypeMenu, setOpenTypeMenu] = useState(false);
  const params = new URLSearchParams(location.search);
  const activeStatuses = (params.get('status') || '').split(',').filter(Boolean);
  const activeTypes = (params.get('type') || '').split(',').filter(Boolean);
  const { setCurrentUrl } = useContext(breadCrumbsContext);
  const updateBreadCrumbs = useCallback(() => {
    setCurrentUrl([{ label: messages.title.defaultMessage }]);
  }, [setCurrentUrl]);

  const totalItemsQtyStatus = calculateTotalItemsQty(filters.orderStatus);
  const totalItemsQtyType = calculateTotalItemsQty(filters.orderType);
  const statusOptions = addAllOption(filters.orderStatus, totalItemsQtyStatus);
  const typeOptions = addAllOption(filters.orderType, totalItemsQtyType);

  const isAllSelectedStatus = isAllSelected(activeStatuses, statusOptions);
  const isAllSelectedType = isAllSelected(activeTypes, typeOptions);

  useEffect(() => {
    updateBreadCrumbs();
    return () => {
      setCurrentUrl([]);
    };
  }, [updateBreadCrumbs, setCurrentUrl, location]);

  /** redirect from root path */
  if (!activeStatuses?.length && !activeTypes?.length) {
    return <Redirect to={`${baseUrl}${rootPath}?status=${allOption.alias}&type=${allOption.alias}`} />;
  }

  const tablesCommonProps = {
    data: orders,
    totalDocs,
    isLoadingExternal: ordersLoadingState,
    hidePagination: totalDocs <= 10,
    isOrderPage: true,
    options: {
      actionsColumnIndex: -1,
      headerStyle: {
        maxWidth: 'fill-available'
      }
    }
  };

  const handleOpenStatusMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorStatusEl(event.currentTarget);
    setOpenStatusMenu(true);
  };

  const handleCloseStatusMenu = () => {
    setOpenStatusMenu(false);
  };

  const handleOpenTypeMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorTypeEl(event.currentTarget);
    setOpenTypeMenu(true);
  };

  const handleCloseTypeMenu = () => {
    setOpenTypeMenu(false);
  };

  const updateParamsInHistory = (statuses: string[], types: string[]) => {
    if (statuses.length) {
      params.set('status', statuses.join(','));
    }
    if (types.length) {
      params.set('type', types.join(','));
    }

    const queryString = decodeURIComponent(params.toString());
    history.push(`${rootPath}${queryString ? '?' + queryString : ''}`);
  };

  const handleMenuItemClickStatusMenu = (alias: string, checked: boolean) => {
    handleCloseStatusMenu();

    let updatedStatuses;
    if (alias === 'all' && checked) {
      updatedStatuses = [alias];
    } else if (checked) {
      updatedStatuses =
        isAllSelectedStatus || alias === 'all'
          ? [alias]
          : Array.from(new Set([...activeStatuses, alias])).filter((s) => s !== 'all');
    } else {
      updatedStatuses = activeStatuses.filter((status) => status !== alias);
    }

    updateParamsInHistory(updatedStatuses, activeTypes);
  };

  const handleMenuItemClickTypeMenu = (alias: string, checked: boolean) => {
    handleCloseTypeMenu();

    let updatedTypes;
    if (alias === 'all' && checked) {
      updatedTypes = [alias];
    } else if (checked) {
      updatedTypes =
        isAllSelectedType || alias === 'all'
          ? [alias]
          : Array.from(new Set([...activeTypes, alias])).filter((s) => s !== 'all');
    } else {
      updatedTypes = activeTypes.filter((type) => type !== alias);
    }

    updateParamsInHistory(activeStatuses, updatedTypes);
  };

  const activeStatusesLabels = getActiveLabels(activeStatuses, isAllSelectedStatus, statusOptions, allOption.name);
  const activeTypesLabels = getActiveLabels(activeTypes, isAllSelectedType, typeOptions, allOption.name);

  return (
    <div className={clsx(classes.container)}>
      <TitleH1 className={classes.title}>{messages.title.defaultMessage}</TitleH1>

      {/*/!* TODO if some auto pay*!/*/}
      {/*<Alert closable title={"Автоматична оплата"}>*/}
      {/*  <span>*/}
      {/*    Оплату буде автоматично знято після закінчення відліку таймера. Поповніть баланс, щоб уникнути санкцій.*/}
      {/*  </span>*/}
      {/*</Alert>*/}

      {/*/!* TODO if auto clear to catalog*!/*/}
      {/*<Alert type={"warning"} closable title={"Неоформлені замовлення"}>*/}
      {/*  <span>По завершенню відліку часу всі неоформлені товари повернуться у Каталог. Перейти до оформлення</span>*/}
      {/*</Alert>*/}
      <div className={classes.wrapper}>
        <SelectMenu
          label={isMobile ? 'Тип операції' : 'Тип замовлення'}
          options={typeOptions}
          activeItems={activeTypes}
          allSelected={isAllSelectedType}
          anchorEl={anchorTypeEl}
          openMenu={openTypeMenu}
          handleOpenMenu={handleOpenTypeMenu}
          handleCloseMenu={handleCloseTypeMenu}
          handleMenuItemClick={handleMenuItemClickTypeMenu}
          activeLabels={activeTypesLabels}
        />
        <SelectMenu
          label="Статус замовлення"
          options={statusOptions}
          activeItems={activeStatuses}
          allSelected={isAllSelectedStatus}
          anchorEl={anchorStatusEl}
          openMenu={openStatusMenu}
          handleOpenMenu={handleOpenStatusMenu}
          handleCloseMenu={handleCloseStatusMenu}
          handleMenuItemClick={handleMenuItemClickStatusMenu}
          activeLabels={activeStatusesLabels}
        />
      </div>

      <FlrTableV2
        columns={isMobile ? columnsMobile : isTablet ? columnsTablet : TableColumns}
        detailPanel={isMobile ? AccountOrdersTableMobileRowExpanded : null}
        {...tablesCommonProps}
      />
    </div>
  );
};

const mapStateToProps: MapStateToProps<IStateProps, {}, IApplicationState> = (state: IApplicationState) => ({
  accountLoadingState: getAccountLoadingState(state),
  orders: getOrdersList(state),
  ordersLoadingState: getOrdersLoadingState(state),
  totalDocs: getOrdersTotalDocs(state),
  filters: getOrdersFilters(state)
});

export default connect(mapStateToProps)(AccountOrdersTable);
