import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isMobileDevice } from 'environment';
import moment from 'moment';
import { Button, Grid } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';

import ProductDetails from 'components/cards/shared/ProductDetails';
import { SplitedPrice } from 'components/prices';
import { NotEnoughBalanceAlert } from 'components/shared/Alert/NotEnoughBalanceAlert';
import FlrQuantity from 'components/shared/form-elements/quantity/FlrQuantity';
import FlrSelect2 from 'components/shared/form-elements/select2/FlrSelect2';
import Icon from 'components/shared/Icon';
import FlrDialogTitle from 'components/shared/modals/components/DialogTitle';
import { TextBody1, TextBody2, TextHelper } from 'components/shared/text';
import { DetailedProduct, ITransitOrder, ITransitOrderItem, Offer, Product } from 'models';
import { getUserProfile } from 'store/account/selectors';
import { addCartItemAsync, setTransitModal } from 'store/cart/actions';
import { getTransitModalDisableActions, getTransitModalOrders, getTransitModalState } from 'store/cart/selectors';
import { finalizeTransitAsync } from 'store/checkout/actions';
import commonMessages from 'translations/cart/common';
import miniCartMessages from 'translations/cart/mini-cart';
import messages from 'translations/catalog/mini-details';

import { useModalStyles } from './styles';

const isMobile = isMobileDevice(window.navigator);

interface IProps {
  preselectedCount: number;
  product: DetailedProduct | Product | null;
  transitOffer: Offer | undefined;
  handleOpenPayModal: (amount?: number) => void;
}

const OptionComponent = ({ title, order }: { title?: string; order?: ITransitOrder }) => {
  const classes = useModalStyles();

  if (!order) {
    return (
      <Grid container className={classes.optionContainer}>
        <Grid item>
          <TextBody1 component="span" className="main-label">
            Інший пункт призначення
          </TextBody1>
          <TextHelper className="description" component="div" style={{ whiteSpace: 'initial' }}>
            Товар буде доданий у Кошик для подальшого оформлення нового передзамовлення
          </TextHelper>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container className={classes.optionContainer} justifyContent="space-between">
      <Grid item>
        <TextBody1 component="span" className="main-label">
          &#8470;&nbsp;{title}
        </TextBody1>
        <TextHelper className="description" component="div">
          м. {order.orderDetails?.outlet.city}, {order.orderDetails?.outlet.street},{' '}
          {order.orderDetails?.outlet.building}.
        </TextHelper>
        <TextHelper className="description" component="div">
          Доставка: {order.orderDetails?.deliveryMethod?.name}, {moment(order.deliveryDate).format('DD.MM.yy')},{' '}
          {order.orderDetails?.deliveryTime?.name}
        </TextHelper>
      </Grid>
      <Grid item>
        <TextHelper className="description" component="div" style={{ textAlign: 'right' }}>
          Сума
        </TextHelper>
        <SplitedPrice fontSize={'body1'} value={order.totalSum} hideDecimal />
      </Grid>
    </Grid>
  );
};

const AddTransitToOfferModal: FC<IProps> = ({ preselectedCount, product, transitOffer, handleOpenPayModal }) => {
  const dispatch = useDispatch();
  const classes = useModalStyles();
  const userProfile = useSelector(getUserProfile);
  const transitModalState = useSelector(getTransitModalState);
  const transitModalOrders = useSelector(getTransitModalOrders);
  const transitModalDisableActions = useSelector(getTransitModalDisableActions);
  const [selectedOrderId, setSelectedOrderId] = useState<string>('');

  const { fullName, inPackageCount } = product || {};
  const [qtyState, setQtyState] = useState(preselectedCount || inPackageCount || 1);
  const { inStock, endDate, id: transitOfferId } = transitOffer || {};
  const selectedOrder = transitModalOrders?.find((o) => o.id === selectedOrderId);
  const productAlreadyInOrder: ITransitOrderItem[] | undefined = selectedOrder?.items?.filter(
    (i: ITransitOrderItem) => i.flatOffer.productId === product?.id
  );
  const productAlreadyInOrderPrices = productAlreadyInOrder?.length ? productAlreadyInOrder.map((p) => p.price) : [];
  const totalProductsAlreadyInOrder = productAlreadyInOrder?.length
    ? productAlreadyInOrder.reduce((acc, p) => acc + p.qty, 0)
    : 0;
  const transitOfferPrice: any = transitOffer
    ? Number(Object.values(transitOffer.price)[0]) * (1 - transitOffer.discount)
    : 0;
  const transitOfferTotalPrice: number = transitOfferPrice * qtyState;
  const availableFunds = (userProfile && userProfile.availableFunds) || 0;
  // const notEnough = transitOfferTotalPrice > availableFunds && selectedOrderId && selectedOrderId !== 'other';
  const notEnough = availableFunds < 0;

  const min = inPackageCount || 1;
  const max = inStock;

  useEffect(() => {
    setQtyState(preselectedCount);
  }, [preselectedCount]);

  const handleClose = () => {
    dispatch(setTransitModal({ isOpen: false, orders: null }));
    setQtyState(inPackageCount || 1);
    setSelectedOrderId('');
  };

  const handleAdd = () => {
    if (selectedOrderId === 'other') {
      dispatch(addCartItemAsync.request({ offer: transitOfferId, qty: qtyState }));
    } else {
      dispatch(
        finalizeTransitAsync.request({
          orderId: selectedOrderId,
          offer: transitOfferId,
          qty: qtyState
        })
      );
    }
    setSelectedOrderId('');
  };

  const onSelectOrder = (event: any, ...args: any) => {
    setSelectedOrderId(event.target.value);
  };

  const orderOptions = useMemo(() => {
    const otherOption: any = {
      value: 'other',
      label: 'Інший пункт призначення',
      render: <OptionComponent />
    };

    if (transitModalOrders && transitModalOrders.length) {
      return transitModalOrders
        .map((o) => {
          const label = `${o.orderId} від ${moment(endDate).format('DD.MM.yy')}. ${o.orderDetails?.outlet.city}`;
          return {
            value: o.id,
            label,
            render: <OptionComponent title={label} order={o} />
          };
        })
        .concat([otherOption]);
    }
    return [otherOption];
    // eslint-disable-next-line
  }, [transitModalOrders]);

  return (
    <Dialog
      open={!!transitModalState}
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      scroll={'body'}
      classes={{
        paperScrollBody: classes.dialogContainer
      }}
    >
      {handleClose ? (
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 16,
            top: 16
          }}
          className={classes.closeButton}
        >
          <Icon type={'cross'} size={12} />
        </IconButton>
      ) : null}
      <FlrDialogTitle text={messages.transitModalTitle} classNameTitle={classes.transitOfferTitle} />
      <TextBody2 className={classes.titleSecondary}>
        {messages.transitToOrderTitle1.defaultMessage}
        <br />
        {messages.transitToOrderTitle2.defaultMessage}
      </TextBody2>

      {notEnough && (
        <NotEnoughBalanceAlert
          addBalance={handleOpenPayModal}
          amount={availableFunds}
          className={classes.alertContainer}
        />
      )}

      {product ? (
        <Grid className={classes.rowContainer} alignItems="center" justifyContent="center" container>
          <TextBody2 className={classes.productName}>{fullName}</TextBody2>
          <ProductDetails
            product={product as Product}
            className={'nowrap'}
            attrsListStyle={{ justifyContent: 'center' }}
            attrsOnly
          />
        </Grid>
      ) : null}

      <Grid className={classes.rowContainer} item container xs={12} spacing={1} alignContent={'flex-start'}>
        <Grid item xs={12}>
          <FlrSelect2
            name="transitOrder"
            fullWidth
            label={messages.transitChooseOrderLabel.defaultMessage}
            options={orderOptions}
            onChange={onSelectOrder}
            value={selectedOrderId}
          />
        </Grid>
        <Grid item container xs={12} className={classes.infoDelivery}>
          <Grid item container direction={'column'} style={{ display: 'flex' }}>
            {!selectedOrderId || selectedOrderId === 'other' ? (
              <TextHelper style={{ textAlign: 'initial' }}>{messages.transitChooseOrderHint.defaultMessage}</TextHelper>
            ) : (
              <Grid container justifyContent="space-between">
                <Grid item className={classes.transitOrderHint}>
                  <TextHelper className="description" component="div">
                    м. {selectedOrder?.orderDetails?.outlet.city}, {selectedOrder?.orderDetails?.outlet.street},{' '}
                    {selectedOrder?.orderDetails?.outlet.building}.
                  </TextHelper>
                  <TextHelper className="description" component="div">
                    Доставка: {selectedOrder?.orderDetails?.deliveryMethod?.name},{' '}
                    {moment(selectedOrder?.deliveryDate).format('DD.MM.yy')}, &nbsp;
                    {selectedOrder?.orderDetails?.deliveryTime?.name}
                  </TextHelper>
                </Grid>
                <Grid item>
                  <TextHelper>{messages.transitChooseOrderSum.defaultMessage}</TextHelper>
                  <SplitedPrice
                    fontColor="grayedText"
                    fontSize="h5"
                    fontSizeDecimal="h5"
                    value={selectedOrder?.totalSum || 0}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid xs={12}>
          <FlrQuantity
            value={qtyState}
            inPackageCount={inPackageCount}
            min={min}
            maxOptional={max}
            onChange={setQtyState}
            withoutActionButtons
            label="Кількість, шт"
            fullWidth
            hideKeyboard={isMobile}
            isSelect
            classNameWrapper={classes.quantityContainer}
          />
        </Grid>
      </Grid>

      <Grid
        className={classes.transitProductContainer}
        container
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item container direction="column" className={classes.transitItemInfo}>
          <Grid className={classes.transitProductColumnTitle} item>
            {messages.inPackageCountLabel.defaultMessage}
          </Grid>
          <Grid item>
            <TextBody2>{inPackageCount}</TextBody2>
          </Grid>
        </Grid>
        <Grid item container direction="column" className={classes.transitItemInfo}>
          <Grid className={classes.transitProductColumnTitle} item>
            {messages.alreadyInOrderLabel.defaultMessage}
          </Grid>
          <Grid item>
            <TextBody2>{totalProductsAlreadyInOrder}</TextBody2>
          </Grid>
        </Grid>
        <Grid item container direction="column" className={classes.transitItemInfo}>
          <Grid className={classes.transitProductColumnTitle} item>
            {messages.transitOrderColumn2.defaultMessage}
          </Grid>
          <Grid item>
            <SplitedPrice fontColor="inherit" fontSize="h5" fontSizeDecimal="h5" value={transitOfferPrice} className={classes.transitOfferPrice} />
          </Grid>
        </Grid>
        <Grid item container direction="column" className={classes.transitItemInfo}>
          <Grid className={classes.transitProductColumnTitle} item>
            {messages.transitOrderColumn4.defaultMessage}
          </Grid>
          <Grid item>
            <SplitedPrice fontColor={notEnough ? 'red' : 'inherit'} value={transitOfferTotalPrice} fontSize="h5" fontSizeDecimal="h5" className={classes.transitOfferPrice} />
          </Grid>
        </Grid>
      </Grid>

      {productAlreadyInOrder && productAlreadyInOrder.length && selectedOrderId !== 'other' ? (
        <TextBody2 className={classes.transitToOrderBottomText}>
          {messages.transitToOrderBottomText1.defaultMessage}
          {productAlreadyInOrderPrices?.length
            ? productAlreadyInOrderPrices.map((price) => (
                <>
                  <SplitedPrice fontSize="h5" fontSizeDecimal="h5" fontColor="black" value={price} />
                </>
              ))
            : null}
          {messages.transitToOrderBottomText2.defaultMessage}
        </TextBody2>
      ) : null}
      <DialogActions>
        {!isMobile ? (
          <Button
            className={classes.actionButtonCancel}
            disabled={transitModalDisableActions}
            color={'primary'}
            onClick={handleClose}
          >
            {commonMessages.cancelText.defaultMessage}
          </Button>
        ) : null}
        {notEnough ? (
          <Button
            disabled={transitModalDisableActions}
            variant={'contained'}
            color={'primary'}
            className={classes.actionButton}
            onClick={() => handleOpenPayModal(transitOfferTotalPrice - availableFunds)}
          >
            {commonMessages.refoundBalance.defaultMessage}
          </Button>
        ) : (
          <Button
            disabled={transitModalDisableActions || !selectedOrderId}
            variant={'contained'}
            color={'primary'}
            onClick={handleAdd}
            className={classes.actionButton}
          >
            {selectedOrderId === 'other'
              ? miniCartMessages.addToCart.defaultMessage
              : miniCartMessages.orderAndBuy.defaultMessage}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export { AddTransitToOfferModal };
