import { FC, useState } from 'react';
import { Button } from '@mui/material';

import { User } from 'models';

import { SplitedPrice } from '../../../components/prices';
import FlrCard from '../../../components/shared/card/FlrCard';
import PaymentModal from '../../../components/shared/modals/payment/PaymentModal';
import { TitleH1 } from '../../../components/shared/text';
import messages from '../../../translations/account/finance';
import FinanceTabs from './FinanceTabs';

import classes from './FinanceInfo.module.scss';

interface IFinanceInfoProps {
  account: User;
}

const FinanceInfo: FC<IFinanceInfoProps> = ({ account }) => {
  const [modalPayOpen, setModalPayOpen] = useState(false);

  const handlePayCancel = () => {
    setModalPayOpen(false);
  };

  const handleOpenPayModal = () => {
    setModalPayOpen(true);
  };

  const actualBalance = account && account.profile.actualBalance;
  const balance = account && account.profile.balance;
  const credit = account && account.profile.credit;
  return (
    <div className={classes.wrapper}>
      <FinanceTabs classNameWrapper={classes.tableToolbarContainer} />
      <FlrCard hoverShadow className={classes.balanceContainer}>
        <div className={classes.balanceContent}>
          <h3 className={classes.balanceTitle}>Баланси</h3>
          <Button
            color="primary"
            variant={'contained'}
            size="small"
            onClick={handleOpenPayModal}
            className={classes.btn}
          >
            {messages.topUp.defaultMessage}
          </Button>
        </div>
        <div className={classes.balanceContentTablet}>
          <div className={classes.balanceContent}>
            <>
              <span className={classes.balanceItemTitle}>{messages.factBalance.defaultMessage}</span>
              <SplitedPrice value={actualBalance} className={classes.splitedPrice} />
            </>
            <div className={classes.creditContainer}>
              <span className={classes.balanceItemSubTitle}>{messages.credit.defaultMessage}</span>
              <SplitedPrice value={credit} className={classes.splitedPriceItem} />
            </div>
          </div>
          <div className={classes.totalBalanceContainer}>
            <div className={classes.itemTotalBalance}>
              <span className={classes.balanceItemTitle}>{messages.totalBalance.defaultMessage}</span>
              <SplitedPrice value={balance} className={classes.splitedPrice} />
            </div>
            <div className={classes.itemTotalBalance}>
              <span className={classes.balanceItemSubTitle}>{messages.factBalanceValue.defaultMessage}</span>
              <SplitedPrice value={actualBalance} className={classes.splitedPriceItem} />
            </div>
            <div className={classes.itemTotalBalance}>
              <span className={classes.balanceItemSubTitle}>{messages.orderInPending.defaultMessage}</span>
              <SplitedPrice
                value={-1 * (account?.profile?.operatingOrdersTotalSum || actualBalance - balance)}
                className={classes.splitedPriceItem}
              />
            </div>
          </div>
        </div>
      </FlrCard>
      <TitleH1 className={classes.titleTablet}>{messages.title.defaultMessage}</TitleH1>
      {modalPayOpen && <PaymentModal open={modalPayOpen} handleClose={handlePayCancel} amount={actualBalance} />}
    </div>
  );
};

export default FinanceInfo;
