import React from 'react';
import { Element as ScrollBlock } from 'react-scroll';
import clsx from 'clsx';
import upperFirst from 'lodash/upperFirst';
import moment from 'moment';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';

import { Text } from 'components/layout/content/Text';
import FlrLoader from 'components/loading/LoadingSpinner';
import { NotEnoughBalanceAlert } from 'components/shared/Alert/NotEnoughBalanceAlert';
import FlrCard from 'components/shared/card/FlrCard';
import Icon from 'components/shared/Icon';
import { TitleH2 } from 'components/shared/text';
import { GTM } from 'controllers';
import { CartGroup, CartItem } from 'models';
import messages from 'translations/cart/common';
import messagesExpired from 'translations/cart/expired';
import messagesMiniDetails from 'translations/catalog/mini-details';
import { getMinMaxPreOrderDates } from 'utils/time';

import { INFINITE } from '../../../shared/constants';
import CartCardItem from './CartGroupCardItem';
import CartGroupCardItemsHeaders from './CartGroupCardItemsHeaders';
import CartGroupCardSubtotal from './CartGroupCardSubtotal';
import styles from './styles';

interface IProps {
  balance?: number;
  cartGroupName: string;
  cartGroup: CartGroup;
  expired?: boolean;
  preOrder?: boolean;
  anchor: string;
  addBalance?: () => void;
  onQtyUpdate: (item: CartItem) => void;
  onRemoveItem: (item: CartItem) => void;
  onSubmit: (item: CartGroup) => void;
  onRemove?: (item: CartGroup) => void;
  classes: any;
}

const CartGroupCard: React.FC<IProps> = ({
  balance = 0,
  addBalance,
  cartGroupName,
  cartGroup,
  expired = false,
  preOrder = false,
  anchor,
  classes,
  onRemoveItem,
  onSubmit,
  onRemove,
  onQtyUpdate
}) => {
  const itemsNumber = cartGroup.items.length;
  const notEnough = !expired && balance < 0;
  const handleQtyChange = (item: CartItem, qty: number) => {
    const newItem = new CartItem(item);
    newItem.qty = qty;
    onQtyUpdate(newItem);
  };

  const handleProcessButton = () => {
    if (expired && !preOrder) {
      GTM.trackRestoreExpiredButton(cartGroup);
    }
    if (notEnough) {
      if (addBalance) {
        addBalance();
      }
    } else {
      onSubmit(cartGroup);
    }
  };

  const handleRemove = () => {
    if (onRemove) {
      GTM.trackRemoveItemsFromCart(cartGroup);
      onRemove(cartGroup);
    }
  };

  const discount = cartGroup.discount;
  const totalQty = cartGroup.totalQty;
  const allArchived = cartGroup.allArchived;

  const deliveryOptions: any[] = getMinMaxPreOrderDates([cartGroup.items[0]], cartGroup.preOrder, cartGroup.endDate);

  return (
    <ScrollBlock
      name={`${anchor}-${cartGroupName}`}
      className={clsx(`${anchor}-item`, classes.orderCard, { [classes.expired]: expired })}
    >
      <FlrCard id={cartGroupName} className={clsx({ [classes.expired]: expired })} style={{ padding: 0 }}>
        {notEnough ? (
          <NotEnoughBalanceAlert
            addBalance={addBalance}
            amount={balance}
            withoutMarginTop
          />
        ) : null}
        <div className={classes.deliveryHeader}>
          <TitleH2 className={clsx(classes.noMarginTop, classes.heading)}>
            {cartGroup.preOrder ? 'Флоротека' : cartGroup.warehouse.displayName}&nbsp;
          </TitleH2>
          <div className={classes.transitDeliveryTypeContainer}>
            {cartGroup.transit ? (
              <>
                <Grid item container xs={7} alignItems="center" wrap="nowrap">
                  <Icon type="arriving" size={24} offset={8} opacity={1} />
                  <span className={classes.transitDeliveryTime}>
                    {upperFirst(moment(cartGroup.endDate).format('dd DD.MM'))}
                  </span>
                </Grid>
                <div className={classes.transitDeliveryText}>
                  {messagesMiniDetails.transitDeliveryText.defaultMessage}
                </div>
              </>
            ) : null}
            <div className={classes.deliveryDatesContainer}>
              <Icon type={cartGroup.preOrder ? 'box' : 'delivery'} size={24} offset={8} />
              <span>
                <Text bold>{upperFirst(deliveryOptions[0] ? deliveryOptions[0].format('dd DD.MM') : '')}</Text>
                {' - '}
                {cartGroup.preOrder
                  ? INFINITE
                  : upperFirst(deliveryOptions[1] ? deliveryOptions[1].format('dd DD.MM') : '')}
              </span>
            </div>
          </div>
        </div>
        <div className={classes.content}>
          <CartGroupCardItemsHeaders itemsNumber={itemsNumber} />
          {cartGroup.items.map((item: CartItem, index: number) => (
            <CartCardItem
              expired={expired}
              max={item.offer.inStock}
              key={`cart-group-item-${index}`}
              item={item}
              withDiscount={!!discount}
              onChangeAmount={(qty: number) => handleQtyChange(item, qty)}
              onRemove={() => onRemoveItem(item)}
              refreshQtyFromProps
            />
          ))}
          <Grid item container alignItems={'center'} alignContent={'flex-end'} className={classes.cardActions}>
            <CartGroupCardSubtotal
              onRemove={onRemove ? handleRemove : undefined}
              itemsNumber={totalQty}
              amount={cartGroup.subtotal - cartGroup.discount}
              discount={discount}
              isAvailable={!allArchived}
              hasPreOrder={preOrder}
            >
              <Button
                disabled={cartGroup.processing}
                color={'primary'}
                variant={!expired && preOrder ? 'outlined' : 'contained'}
                onClick={handleProcessButton}
                className={classes.checkoutOrderFooterBlock}
              >
                {cartGroup.processing && <FlrLoader size={16} />}
                {notEnough ? (
                  messages.notEnoughFundsBtn.defaultMessage
                ) : (
                  <>
                    {expired && preOrder && messagesExpired.restoreAndPreOrder.defaultMessage}
                    {expired && !preOrder && messagesExpired.restore.defaultMessage}
                    {!expired && preOrder && messages.preOrder.defaultMessage}
                    {!expired && !preOrder && messages.toDelivery.defaultMessage}
                  </>
                )}
              </Button>
            </CartGroupCardSubtotal>
          </Grid>
        </div>
      </FlrCard>
    </ScrollBlock>
  );
};

export default withStyles<any>(styles)(CartGroupCard);
