import { OrderStatus } from "./order";

export const PERSONAL_DISCOUNT = "personal";
export const PRODUCT_GROUP_DISCOUNT = "productGroup";

export interface IProductGroupDiscountData {
  alias: string;
  code: string;
  name: string;
}

export interface IProductGroupDiscount {
  percent: number;
  amount: number;
  requireAmount: number;
  productGroup: IProductGroupDiscountData;
  image: string;
}

export interface IPersonalDiscount {
  percent: number;
  balance: number;
  countOfWeeks: number;
  requireWeeks: number;
  image: string;
}

export interface ICatalogCategoryItemDetails {
  description: string;
  image: string;
  name: string;
}

export interface ICatalogCategoryItem {
  percent: number;
  details: ICatalogCategoryItemDetails;
}

export interface ICatalogCategory {
  [key: string]: ICatalogCategoryItem;
}

export interface IUserDiscount {
  [PERSONAL_DISCOUNT]: IPersonalDiscount;
  [PRODUCT_GROUP_DISCOUNT]: Record<string, IProductGroupDiscount>;
  catalogCategory: ICatalogCategory;
}

export class UserProfile {
  public name: string;
  public firstName: string;
  public lastName: string;
  public fullName: string;
  public phone2: string;
  public businessName: string;
  public files: string[];
  public filesVerified: boolean;
  public availableFunds: number;
  public actualBalance: number
  public balance: number;
  public operatingOrdersTotalSum: number;
  public discount: IUserDiscount;
  public credit: number;
  public orderCredit: number;
  public tmpCredit: number;
  public tmpCreditExpireOn: Date | null;
  public isCreditRequestSent: boolean;
  public EDRPOU: string;
  public ordersSummary: Record<OrderStatus, number>;
  public watchList: string[];

  constructor(userProfile: any) {
    this.name = userProfile.name;
    this.firstName = userProfile.firstName;
    this.lastName = userProfile.lastName;
    this.fullName = userProfile.fullName;
    this.phone2 = userProfile.phone2;
    this.businessName = userProfile.businessName;
    this.files = userProfile.files;
    this.filesVerified = userProfile.filesVerified;
    this.availableFunds = userProfile.availableFunds;
    this.actualBalance = userProfile.actualBalance;
    this.balance = userProfile.balance;
    this.operatingOrdersTotalSum = userProfile.operatingOrdersTotalSum;
    this.discount = userProfile.discount || {};
    this.credit = userProfile.credit;
    this.orderCredit = userProfile.orderCredit;
    this.tmpCredit = userProfile.tmpCredit;
    this.tmpCreditExpireOn = userProfile.tmpCreditExpireOn ? new Date(userProfile.tmpCreditExpireOn) : null;
    this.isCreditRequestSent = userProfile.isCreditRequestSent;
    this.EDRPOU = userProfile.EDRPOU;
    this.ordersSummary = userProfile.ordersSummary || {};
    this.watchList = userProfile.watchList || [];

    /*this.discount = {
      [PERSONAL_DISCOUNT]: {
        balance: 1600,
        percent: 2,
        countOfWeeks: 13,
        requireWeeks: 26
      },
      [PRODUCT_GROUP_DISCOUNT]: {
        "000000969": {
          amount: 7500,
          requireAmount: 2500,
          percent: 5,
          productGroup: {
            alias: "tr-ekvador-kolumbiya",
            name: "Тр. Еквадор/Колумбія",
            code: "000000969"
          }
        }
      }
    };*/
    // spikes for now
    /*if (process.env.NODE_ENV === "development") {
      // personal
      this.discount[PERSONAL_DISCOUNT].balance = 61001;
      this.discount[PERSONAL_DISCOUNT].percent = 2; // Math.ceil(Math.random() * 10);
      this.discount[PERSONAL_DISCOUNT].countOfWeeks = 13; // Math.ceil(Math.random() * 28);
      this.discount[PERSONAL_DISCOUNT].requireWeeks = 26 - this.discount[PERSONAL_DISCOUNT].countOfWeeks;
      //  Math.round(Math.random() + 1) * 28 - this.discount[PERSONAL_DISCOUNT].countOfWeeks;
      // product group
      const keys = Object.keys(this.discount[PRODUCT_GROUP_DISCOUNT]);
      keys.forEach(key => {
        const discProd: IProductGroupDiscount = this.discount[PRODUCT_GROUP_DISCOUNT][key];
        if (key === "000000002") {
          discProd.amount = 7500;
          discProd.requireAmount = 2500;
          discProd.percent = 5;
          return;
        }
        if (discProd.requireAmount === undefined) {
          discProd.requireAmount = 10000 - discProd.amount;
        }
      });
    }*/
  }
}
