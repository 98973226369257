import { ChangeEvent, FC, ReactNode, useContext, useEffect, useState } from 'react';
import { MessageDescriptor } from 'react-intl';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { useLocation } from 'react-router';
import { Link, Route } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import clsx from 'clsx';
import { push } from 'connected-react-router';
import * as _ from 'lodash';
import { Button, Hidden, Tab, Tabs, Theme, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';

import { AsideDeviceFooter, AsideDeviceHeader } from 'components/layout/aside-device';
import SidePanelsLayout from 'components/layout/side-panels-layout';
import Icon from 'components/shared/Icon';
import { TextBody1 } from 'components/shared/text';
import { baseUrl } from 'shared/constants';
import { closeAccountDetails, closeAccountNav, openAccountDetails, openAccountNav } from 'store/account/actions';
import {
  getAccountLoaded,
  getAccountLoadingState,
  getLayoutDetailsOpened,
  getLayoutNavOpened
} from 'store/account/selectors';
import { checkAuthAsync, logout } from 'store/auth/actions';
import { IApplicationState } from 'store/reducers';
import { tabsStyles, tabStyles } from 'styles/components/tabs';
import menuMessages from 'translations/account/menu';

import AccountBreadCrumbs from './AccountBreadCrumbs';
import Assist from './assist';
import { useBreadCrumbs } from './breadCrumbs.hook';
import { breadCrumbsContext } from './breadCrumbsState';
import Dashboard from './dashboard';
import Finance from './finance';
import { AccountLoyalty } from './loyalty';
import Orders from './orders';
import Settings from './settings';

import classes from './Account.module.scss';

export interface IAccountPageTab {
  path?: string;
  href?: string;
  pathRoute?: string;
  label: MessageDescriptor;
  icon: string;
  disabled?: boolean;
  content: FC<any>;
}

export const allTabs: IAccountPageTab[] = [
  {
    path: 'dashboard',
    label: menuMessages.dashboard,
    icon: 'dashboard',
    content: Dashboard
  },
  {
    path: 'orders',
    label: menuMessages.orders,
    icon: 'delivery',
    content: Orders
  },
  {
    path: 'finance',
    pathRoute: 'finance',
    label: menuMessages.finance,
    icon: 'finance',
    content: Finance
  },
  // {
  //   path: "refunds",
  //   label: menuMessages.refunds,
  //   icon: "returnContainer",
  //   content: Refund
  // },
  {
    path: 'loyalty',
    pathRoute: 'loyalty/:discountGroupId?',
    label: menuMessages.loyalty,
    icon: 'loyalty',
    content: AccountLoyalty
  },
  // {
  //   path: "loyalty-mobile",
  //   label: menuMessages.loyaltyMobile,
  //   icon: "loyalty",
  //   content: Loyalty
  // },
  {
    href: 'https://docs.google.com/document/u/1/d/e/2PACX-1vT9kg48w35ZxEW3z_1SDA36we8WaMfCy0YHAVNvRtvODFrejDbeQVjZpj3t2Qhbm26spB6s-IpKVzA7/pub',
    label: menuMessages.assist,
    icon: 'support',
    content: Assist
  },
  {
    path: 'settings',
    label: menuMessages.settings,
    icon: 'settings',
    content: Settings
  }
];
export const notMobileTabs = allTabs;
export const mobileTabs = allTabs.filter((navTab) => !(navTab.path === 'assist'));

export interface IProps {
  tab: string;
  onNavClick?: () => {};
}

interface IStateProps {
  accountLoaded: boolean;
  accountLoading: boolean;
  navOpened: boolean;
  detailsOpened: boolean;
  // Props passed to the component by `connect`
}

interface IDispatchProps {
  checkAuth: typeof checkAuthAsync.request;
  logout: typeof logout;
  openNav: typeof openAccountNav;
  openDetails: typeof openAccountDetails;
  closeNav: typeof closeAccountNav;
  closeDetails: typeof closeAccountDetails;
  push: typeof push;
  // Dispatch props passed to the component by `connect`
}

type IComponentProps = IProps & IStateProps & IDispatchProps;

export const LinkInNewTab = (props: any) => {
  return (
    <a href={props.href} className={clsx(classes.link, props.className)} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  );
};

const AccountPage: FC<IComponentProps> = ({
  tab,
  checkAuth,
  logout: logoutUser,
  accountLoaded,
  accountLoading,
  navOpened,
  detailsOpened,
  closeNav,
  openNav,
  openDetails,
  closeDetails,
  push: redirectTo
}) => {
  const tabClasses = tabStyles();
  const tabsClasses = tabsStyles();

  const theme: Theme = useTheme();
  const screenFullHD = useMediaQuery(theme.breakpoints.up('xl'));
  const screenHD = useMediaQuery(theme.breakpoints.between('lg', 'xl'));
  const screenDevice = useMediaQuery(theme.breakpoints.down('md'));

  const [tabValue, setTabValue] = useState(_.findIndex(allTabs, { path: tab }) || 0);

  // Set current tab by url
  const { pathname } = useLocation();
  const tabIndexByUrl = notMobileTabs.findIndex((item) => {
    return pathname.includes(`${baseUrl}/account/${item.path}`);
  });
  useEffect(() => {
    if (tabIndexByUrl > -1) {
      setTabValue(tabIndexByUrl);
    }
  }, [tabIndexByUrl, setTabValue]);

  const handleTabChange = (event: ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
    if (screenDevice) {
      closeNav();
    } else if (!navOpened) {
      openNav();
    }
  };

  const { setCurrentUrl } = useContext(breadCrumbsContext);
  const [rightPanel, setRightPanel] = useState<() => ReactNode>(() => null);

  const breadCrumbsHook = useBreadCrumbs();

  useEffect(() => {
    setCurrentUrl([]);
  }, [setCurrentUrl]);

  useEffect(() => {
    // we have to be sure user is logged in
    if (!accountLoading) {
      if (accountLoaded) {
        checkAuth();
      } else {
        push(`${baseUrl}/login`);
      }
    }
  }, [accountLoading, accountLoaded, checkAuth]);

  useEffect(() => {
    if (screenFullHD || screenHD) {
      openNav();
      // @ts-ignore
      if (rightPanel) {
        openDetails();
      }
    } else {
      closeNav();
      closeDetails();
    }
  }, [screenFullHD, screenHD, openNav, closeNav, openDetails, closeDetails, rightPanel]);

  const handleLogout = () => {
    logoutUser();
    const redirectPath = `${baseUrl}/`;
    const currentPath = window.location.pathname;
    // we should force main page reload
    if (currentPath === redirectPath || `${currentPath}/` === redirectPath) {
      window.location.reload();
    } else {
      redirectTo(redirectPath);
    }
  };

  const onCloseNav = () => closeNav();
  const logoutBtn = (withBorders?: boolean) => (
    <Button variant={'text'} className={withBorders ? classes.logoutLeftBar : undefined} onClick={handleLogout}>
      <span className={classes.logoutLeftBarWrapper}>
        <Icon type={'signOut'} size={24} offset={16} leftOffset={10} />
        <TextBody1 component={'span'} color={'inherit'}>
          {menuMessages.logoutUser.defaultMessage}
        </TextBody1>
      </span>
    </Button>
  );

  return (
    <breadCrumbsContext.Provider value={breadCrumbsHook}>
      <SidePanelsLayout
        trackAnchor={`account-${tabValue}`}
        topPanel={() => <AccountBreadCrumbs />}
        onLeftPanelClose={onCloseNav}
        accountLeftSideWidth
        leftPanel={() => (
          <>
            <AsideDeviceHeader onClose={onCloseNav} title={menuMessages.title.defaultMessage} accountHeaderHide />
            <h3 className={classes.title}>{menuMessages.title.defaultMessage}</h3>
            <Tabs
              className={'account-tabs'}
              orientation={'vertical'}
              value={tabValue}
              onChange={handleTabChange}
              classes={tabsClasses}
              indicatorColor="primary"
            >
              {notMobileTabs.map((content, key) => (
                <Tab
                  key={key}
                  disabled={!!content.disabled}
                  label={
                    <div className={classes.labelContainer}>
                      <Icon size={24} type={content.icon} offset={16} opacity={1} />
                      <span className={classes.label}>{content.label.defaultMessage}</span>
                    </div>
                  }
                  component={content.href ? LinkInNewTab : Link}
                  to={`${baseUrl}/account/${content.path}`}
                  href={content.href}
                  classes={tabClasses}
                  className={classes.tab}
                />
              ))}
            </Tabs>
            <Hidden lgDown>{logoutBtn(true)}</Hidden>
            <AsideDeviceFooter info={logoutBtn()} />
          </>
        )}
        leftPanelToggleable={true}
        leftPanelOpened={navOpened}
        onRightPanelClose={() => closeDetails()}
        rightPanel={rightPanel}
        rightPanelOpened={!!rightPanel && detailsOpened}
        rightPanelToggleable={false}
        fullScreen={false}
      >
        <div className={classes.container} style={{padding: 0, margin: 0}}>
          {allTabs.map((tabData, key) => {
            const Component = tabData.content;
            return (
              <Route
                // exact
                path={`${baseUrl}/account/${tabData.pathRoute || tabData.path}`}
                // component={tabData.content}
                render={(props) => <Component {...props} setRightPanel={setRightPanel} />}
                key={key}
                // index={key}
              />
            );
          })}
        </div>
      </SidePanelsLayout>
    </breadCrumbsContext.Provider>
  );
};

const mapStateToProps: MapStateToProps<IStateProps, {}, IApplicationState> = (state: IApplicationState) => ({
  accountLoading: getAccountLoadingState(state),
  accountLoaded: getAccountLoaded(state),
  navOpened: getLayoutNavOpened(state),
  detailsOpened: getLayoutDetailsOpened(state)
});

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, {}> = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      checkAuth: checkAuthAsync.request,
      closeNav: closeAccountNav,
      closeDetails: closeAccountDetails,
      openNav: openAccountNav,
      openDetails: openAccountDetails,
      logout,
      push
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountPage);
