import { FC } from 'react';
import { defineMessages } from 'react-intl';
import { useRouteMatch } from 'react-router';
import clsx from 'clsx';
import styled from '@emotion/styled/macro';
import { Grid } from '@mui/material';

import Icon from 'components/shared/Icon';
import { TextBody2 } from 'components/shared/text';
import {
  groupOrdersByStatuses,
  ORDER_STATUS_PACKED,
  ORDER_STATUS_PENDING_APPROVAL,
  ORDER_STATUS_PREORDER,
  ORDER_STATUS_PREORDER_PENDING_APPROVAL,
  ORDER_STATUS_RESERVED,
  ORDER_STATUS_SHIPPED,
  OrderStatus
} from 'models';
import { baseUrl } from 'shared/constants';
import { IThemed } from 'utils/styled';

import { HeaderBottomLink } from './HeaderBottomLink';

import classes from './Header.module.scss';

export const orders = defineMessages({
  label: {
    id: 'catalog.header.orders',
    description: 'orders label',
    defaultMessage: 'Замовлення'
  }
});

interface IHeaderOrdersProps {
  ordersSummary: Record<OrderStatus, number> | null;
}

const ordersUrl = `${baseUrl}/account/orders`;

const Orders: FC<IHeaderOrdersProps> = ({ ordersSummary }) => {
  const isLinkActive = useRouteMatch(ordersUrl);

  const ordersPreorderCount =
    (ordersSummary &&
      groupOrdersByStatuses(ordersSummary, [ORDER_STATUS_PREORDER_PENDING_APPROVAL, ORDER_STATUS_PREORDER])) ||
    0;
  const ordersPendingCount =
    (ordersSummary &&
      groupOrdersByStatuses(ordersSummary, [
        ORDER_STATUS_PENDING_APPROVAL,
        ORDER_STATUS_RESERVED,
        ORDER_STATUS_PACKED
      ])) ||
    0;
  const ordersShippedCount = (ordersSummary && ordersSummary[ORDER_STATUS_SHIPPED]) || 0;

  return (
    <div className={classes.wrapper}>
      <HeaderBottomLink
        to={ordersUrl}
        className={clsx({
          active: isLinkActive
        })}
      >
        <Icon type={'delivery'} className={'menu-cart-icon'} size={32} offset={8} withBackground opacity={1} />
        <Grid container className={classes.mainContent}>
          <Grid item sm={12}>
            <TextBody2 color={'textSecondary'}>{orders.label.defaultMessage}</TextBody2>
          </Grid>
          <Grid item>
            <Value>
              {ordersPreorderCount} | {ordersPendingCount} | {ordersShippedCount}
            </Value>
          </Grid>
        </Grid>
      </HeaderBottomLink>
    </div>
  );
};

const Value = styled<any>('span')(({ theme }: IThemed & any) => ({
  lineHeight: '18px',
  fontSize: theme.fontSizes.default
}));

export { Orders };
