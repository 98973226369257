import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTrackInitialPageLoad } from 'controllers';
import { fetchAccountDiscountsAsync, fetchClientDiscountsAsync } from 'store/account-discounts/actions';
import { getAccountDiscountsData, getAccountDiscountsLoadingState } from 'store/account-discounts/selectors';
import { getUserAccount } from 'store/account/selectors';
import { fetchOrderListAsync } from 'store/order/actions';
import messages from 'translations/account/dashboard';

import { breadCrumbsContext } from '../breadCrumbsState';
import TransactionsTable from '../finance/Table';
import { DashboardFinance } from './components/DashboardFinance';
import { DashboardLoyaltyProgram } from './components/DashboardLoyaltyProgram';
import DashboardDelivery from './DashboardDelivery';

import classes from './Dashboard.module.scss';

const AccountDashboard = () => {
  const dispatch = useDispatch();
  const account = useSelector(getUserAccount);
  const discountConditions = useSelector(getAccountDiscountsData);
  const discountConditionsLoading = useSelector(getAccountDiscountsLoadingState);
  const { setCurrentUrl } = useContext(breadCrumbsContext);
  const updateBreadCrumbs = () => {
    setCurrentUrl([{ label: messages.title.defaultMessage }]);
  };

  useEffect(() => {
    if (!discountConditions && !discountConditionsLoading) {
      dispatch(fetchClientDiscountsAsync.request());
      dispatch(fetchAccountDiscountsAsync.request());
      dispatch(fetchOrderListAsync.request('?status=all&type=all'));
    }
    updateBreadCrumbs();
  }, []);

  useTrackInitialPageLoad();

  return (
    <div className={classes.wrapper}>
      <h3 className={classes.title}>Інфопанель</h3>
      <div className={classes.container}>
        <div className={classes.leftSide}>
          <DashboardDelivery account={account} />
          <DashboardFinance />
          <TransactionsTable isDashboard />
        </div>
        <div className={classes.rightSide}>
          <section className={classes.financesSection}>
            <DashboardLoyaltyProgram />
          </section>
        </div>
      </div>
    </div>
  );
};

export default AccountDashboard;
