import { FC } from 'react';

import FlrCard from 'components/shared/card/FlrCard';
import { Link2 } from 'components/shared/text';
import { IPurchasedQty } from 'models/account-discounts';
import { baseUrl } from 'shared/constants';
import messages from 'translations/account/dashboard';

import classes from './DashboardLoyaltyProgramList.module.scss';

interface IProps {
  purchasedQty: IPurchasedQty[];
}
const DashboardLoyaltyProgramList: FC<IProps> = ({ purchasedQty }) => {
  return (
    <FlrCard hoverShadow className={classes.card}>
      <div className={classes.titleContainer}>
        <h4 className={classes.title}>Знижки на групи товарів</h4>
        <Link2
          to={`${baseUrl}/account/loyalty`}
          classes={{
            root: classes.link
          }}
        >
          Детальніше
        </Link2>
      </div>
      <span className={classes.subTitle}>{messages.groupDiscount.defaultMessage}</span>
      <div className={classes.list}>
        {purchasedQty.map((item, index) => (
          <div className={classes.wrapper} key={index}>
            <span className={classes.name}>{item.name}</span>
            <span className={classes.percent}>{item.currentDiscount.percent}%</span>
          </div>
        ))}
      </div>
    </FlrCard>
  );
};

export { DashboardLoyaltyProgramList };
