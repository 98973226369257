import { FC, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import clsx from 'clsx';
import { IconButton, Menu, MenuItem } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

import Icon from '../../../components/shared/Icon';

import classes from './SelectFinance.module.scss';

const options = [
  { label: 'Загальний', value: 'total' },
  { label: 'Фактичний', value: 'fact' }
];

interface ISelectBalanceProps {
  isDashboard?: boolean;
}

const SelectBalance: FC<ISelectBalanceProps> = ({ isDashboard }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const isCompletedOnly = params.has('completedOnly');
  const history = useHistory();

  const handleOpenMenu = (event: any) => {
    setOpenMenu(!openMenu);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };

  const handleSelect = (value: string) => {
    if (value === 'total') {
      params.delete('completedOnly');
    } else if (value === 'fact') {
      params.set('completedOnly', '1');
    }

    params.delete('page');

    history.push(`${location.pathname}?${decodeURIComponent(params.toString())}`);

    setOpenMenu(false);
  };

  const activeLabel = isCompletedOnly
    ? options.find((option) => option.value === 'fact')?.label || ''
    : options.find((option) => option.value === 'total')?.label || '';

  return (
    <div className={clsx(classes.toolbarContainer, {[classes.dashboardAction]: isDashboard})}>
      <IconButton onClick={handleOpenMenu} className={classes.button}>
        <span className={classes.label}>Баланс:</span>&nbsp;
        <span className={classes.selectedLabel}>{activeLabel}</span>
        <Icon
          type="arrow"
          size={12}
          leftOffset={8}
          className={clsx(classes.arrow, { [classes.arrowRotate]: openMenu })}
        />
      </IconButton>
      <Menu anchorEl={anchorEl} open={openMenu} onClose={handleCloseMenu}>
        {options.map((financeItem) => (
          <MenuItem key={financeItem.value} onClick={() => handleSelect(financeItem.value)}>
            <FormControlLabel
              control={<Radio checked={isCompletedOnly === (financeItem.value === 'fact')} />}
              label={financeItem.label}
            />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export { SelectBalance };
