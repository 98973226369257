import { call, put, select, takeEvery } from 'redux-saga/effects';
import { push, replace } from 'connected-react-router';
import { createAction, createAsyncAction, createReducer, PayloadAction } from 'typesafe-actions';

import { IPanelsLayout } from 'components/layout/side-panels-layout';
import { IPreferencesData, IRecommendedPayment, User } from 'models/user';
import {
  errorDataWrapper,
  IAsyncDataWrapper,
  loadedDataWrapper,
  loadingDataWrapper,
  refreshDataWrapper,
  REQUEST_ACTIONS
} from 'store/actions';
import { logout } from 'store/auth/actions';
import messages from 'translations/account/settings';

import { baseUrl } from '../../shared/constants';
import { extractDigitsFromString } from '../../utils/helpers';
import { PRODUCT_WATCH_REQUEST, watchProductAsync } from '../catalog/actions';
import { enqueueSnackbarError, enqueueSnackbarSuccess } from '../layout';
import { AccountRepository } from './request';
import { getUserAccount } from './selectors';

const httpClient = new AccountRepository();

export const prefix = '@@account/';

export const ACCOUNT_RESET = `${prefix}RESET`;
export const ACCOUNT_REQUEST = `${prefix}${REQUEST_ACTIONS.REQUEST}`;
export const ACCOUNT_REQUEST_SUCCESS = `${prefix}${REQUEST_ACTIONS.SUCCESS}`;
export const ACCOUNT_REQUEST_FAILURE = `${prefix}${REQUEST_ACTIONS.FAILURE}`;

export const ACCOUNT_UPDATE_REQUEST = `${prefix}update/${REQUEST_ACTIONS.REQUEST}`;
export const ACCOUNT_UPDATE_REQUEST_SUCCESS = `${prefix}update/${REQUEST_ACTIONS.SUCCESS}`;
export const ACCOUNT_UPDATE_REQUEST_FAILURE = `${prefix}update/${REQUEST_ACTIONS.FAILURE}`;

export const ACCOUNT_PREFERENCES_UPDATE_REQUEST = `${prefix}update/preferences/${REQUEST_ACTIONS.REQUEST}`;
export const ACCOUNT_PREFERENCES_UPDATE_REQUEST_SUCCESS = `${prefix}update/preferences/${REQUEST_ACTIONS.SUCCESS}`;
export const ACCOUNT_PREFERENCES_UPDATE_REQUEST_FAILURE = `${prefix}update/preferences/${REQUEST_ACTIONS.FAILURE}`;

export const ACCOUNT_OPEN_NAV = `${prefix}OPEN_NAV`;
export const ACCOUNT_CLOSE_NAV = `${prefix}CLOSE_NAV`;
export const ACCOUNT_TOGGLE_NAV = `${prefix}TOGGLE_NAV`;

export const ACCOUNT_OPEN_DETAILS = `${prefix}OPEN_DETAILS`;
export const ACCOUNT_CLOSE_DETAILS = `${prefix}CLOSE_DETAILS`;
export const ACCOUNT_TOGGLE_DETAILS = `${prefix}TOGGLE_DETAILS`;

export const ACCOUNT_RECOMMENDED_AMOUNT = `${prefix}RECOMMENDED_AMOUNT`;
export const ACCOUNT_RECOMMENDED_AMOUNT_SUCCESS = `${prefix}RECOMMENDED_AMOUNT_SUCCESS`;
export const ACCOUNT_RECOMMENDED_AMOUNT_FAILURE = `${prefix}RECOMMENDED_AMOUNT_FAILURE`;

export const RESTORE_CART_AND_REDIRECT = `${prefix}RESTORE_CART_AND_REDIRECT`;

interface IAccountStateSync {
  user: User | null;
  layout: IPanelsLayout;
  recommendedPayment: IRecommendedPayment | null;
}

export type IAccountState = IAsyncDataWrapper<IAccountStateSync>;

export const accountInitialState: IAccountState = {
  loading: false,
  loaded: false,
  data: {
    user: null,
    layout: {
      leftOpened: false,
      rightOpened: false
    },
    recommendedPayment: null
  },
  error: null
};

type AccountActionTypes = typeof ACCOUNT_REQUEST | typeof ACCOUNT_REQUEST_SUCCESS | typeof ACCOUNT_REQUEST_FAILURE;

type AccountUpdateActionTypes =
  | typeof ACCOUNT_UPDATE_REQUEST
  | typeof ACCOUNT_UPDATE_REQUEST_SUCCESS
  | typeof ACCOUNT_UPDATE_REQUEST_FAILURE;

export const fetchAccountAsync = createAsyncAction(ACCOUNT_REQUEST, ACCOUNT_REQUEST_SUCCESS, ACCOUNT_REQUEST_FAILURE)<
  void,
  User,
  Error
>();

export const updateAccountAsync = createAsyncAction(
  ACCOUNT_UPDATE_REQUEST,
  ACCOUNT_UPDATE_REQUEST_SUCCESS,
  ACCOUNT_UPDATE_REQUEST_FAILURE
)<User, User, Error>();

export const updatePreferencesAccountAsync = createAsyncAction(
  ACCOUNT_PREFERENCES_UPDATE_REQUEST,
  ACCOUNT_PREFERENCES_UPDATE_REQUEST_SUCCESS,
  ACCOUNT_PREFERENCES_UPDATE_REQUEST_FAILURE
)<Partial<IPreferencesData>, User, Error>();

export const fetchPaymentRecommendedAmount = createAsyncAction(
  ACCOUNT_RECOMMENDED_AMOUNT,
  ACCOUNT_RECOMMENDED_AMOUNT_SUCCESS,
  ACCOUNT_RECOMMENDED_AMOUNT_FAILURE
)<void, IRecommendedPayment, Error>();

export const resetAccount = createAction(ACCOUNT_RESET)();
export const openAccountNav = createAction(ACCOUNT_OPEN_NAV)<void>();
export const closeAccountNav = createAction(ACCOUNT_CLOSE_NAV)<void>();
export const toggleAccountNav = createAction(ACCOUNT_TOGGLE_NAV)<void>();

export const openAccountDetails = createAction(ACCOUNT_OPEN_DETAILS)<void>();
export const closeAccountDetails = createAction(ACCOUNT_CLOSE_DETAILS)<void>();
export const toggleAccountDetails = createAction(ACCOUNT_TOGGLE_DETAILS)<void>();
export const restoreCartAndRedirect = createAction(RESTORE_CART_AND_REDIRECT)<string>();

export function* accountSaga(): Generator {
  try {
    const response: any = yield call(() => httpClient.fetch());

    // redirect to maintenance-mode page
    if (response.response && response.response.status === 503 && response.response.data === 'Site Maintenance') {
      yield put(fetchAccountAsync.failure(new Error('Site Maintenance')));
      yield put(replace('/maintenance-mode'));
    } else {
      if (response && (response.status === 401 || response.status === 403 || response.status === 503)) {
        window.localStorage.setItem('redirect_after_login', window.location.pathname);
        yield put({ type: '@@auth/LOGOUT' });
        window.location.href = '/login';
      } else {
        const user = new User(response.data);
        yield put(fetchAccountAsync.success(user));
      }
    }
  } catch (err) {
    yield put(fetchAccountAsync.failure(err as Error));
  }
}

function* restoreCartRedirectSaga(action: ReturnType<typeof restoreCartAndRedirect>): Generator {
  try {
    const response: any = yield call(() => httpClient.repeatOrder(action.payload));
    console.log(response);
    // TODO
    yield put(push(`${baseUrl}/cart`));
  } catch (err) {
    console.log(err);
  }
}

function* accountUpdateSaga(action: ReturnType<typeof updateAccountAsync.request>): Generator {
  try {
    const response: any = yield call(() => httpClient.update(action.payload));
    const data = {
      ...response.data,
      phone: extractDigitsFromString(response.data.phone)
    };
    const user = new User(data);
    yield put(updateAccountAsync.success(user));
    yield put(enqueueSnackbarSuccess({ message: messages.notificationSuccessUpdated.defaultMessage }));
  } catch (err) {
    yield put(updateAccountAsync.failure(err as Error));
    yield put(enqueueSnackbarError());
  }
}

function* paymentRecommendedAmountSaga(): Generator {
  try {
    const response: any = yield call(() => httpClient.fetchRecommendedAmount());
    yield put(fetchPaymentRecommendedAmount.success(response.data));
  } catch (err: any) {
    yield put(fetchPaymentRecommendedAmount.failure(err as Error));
  }
}

function* accountPreferencesUpdateSaga(action: ReturnType<typeof updatePreferencesAccountAsync.request>): Generator {
  try {
    const userData: any = yield select(getUserAccount);
    if (userData.profile && userData.profile.discount) {
      delete userData.profile.discount;
    }
    const requestData: User = {
      ...userData,
      preferences: {
        ...userData.preferences,
        ...action.payload
      }
    };
    const response: any = yield call(() => httpClient.update(requestData));
    const data = {
      ...response.data,
      phone: extractDigitsFromString(response.data.phone)
    };
    const user = new User(data);
    yield put(updatePreferencesAccountAsync.success(user));
  } catch (err) {
    yield put(updatePreferencesAccountAsync.failure(err as Error));
  }
}

export function* accountRequestSaga() {
  yield takeEvery(fetchAccountAsync.request, accountSaga);
  yield takeEvery(updateAccountAsync.request, accountUpdateSaga);
  yield takeEvery(updatePreferencesAccountAsync.request, accountPreferencesUpdateSaga);
  yield takeEvery(restoreCartAndRedirect, restoreCartRedirectSaga);
  yield takeEvery(logout, resetAccount);
  yield takeEvery(fetchPaymentRecommendedAmount.request, paymentRecommendedAmountSaga);
}

export default createReducer(accountInitialState)
  .handleAction(fetchAccountAsync.request, (state: IAccountState) => loadingDataWrapper(state.data))
  .handleAction(fetchAccountAsync.success, (state: IAccountState, action: PayloadAction<AccountActionTypes, User>) =>
    loadedDataWrapper({
      ...state.data,
      user: action.payload
    })
  )
  .handleAction(fetchAccountAsync.failure, (state: IAccountState) =>
    errorDataWrapper({ ...state.data, user: null }, new Error('Failed to load account'))
  )

  .handleAction(updateAccountAsync.request, (state: IAccountState) => refreshDataWrapper(state.data))
  .handleAction(
    updateAccountAsync.success,
    (state: IAccountState, action: PayloadAction<AccountUpdateActionTypes, User>) =>
      loadedDataWrapper({
        ...state.data,
        user: action.payload
      })
  )
  .handleAction(
    updatePreferencesAccountAsync.success,
    (state: IAccountState, action: PayloadAction<AccountUpdateActionTypes, User>) =>
      loadedDataWrapper({
        ...state.data,
        user: action.payload
      })
  )
  .handleAction(updateAccountAsync.failure, (state: IAccountState) =>
    errorDataWrapper(state.data, new Error('Failed to update account'), false, true)
  )
  .handleAction(updatePreferencesAccountAsync.failure, (state: IAccountState) =>
    errorDataWrapper(state.data, new Error('Failed to update preferences on account'), false, true)
  )
  .handleAction(
    watchProductAsync.success,
    (
      state: IAccountState,
      action: PayloadAction<typeof PRODUCT_WATCH_REQUEST, { productId: string; watch: boolean }>
    ) => {
      const user = state.data.user;
      if (!user) {
        return loadedDataWrapper({
          ...state.data
        });
      }
      const watchList = user.profile.watchList;

      if (!action.payload.watch) {
        user.profile.watchList = watchList.filter((item) => item !== action.payload.productId);
      } else {
        user.profile.watchList.push(action.payload.productId);
      }

      return loadedDataWrapper({
        ...state.data,
        user: new User({ ...user })
      });
    }
  )
  .handleAction(ACCOUNT_TOGGLE_NAV, (state: IAccountState) => {
    return loadedDataWrapper({
      ...state.data,
      layout: {
        ...state.data.layout,
        leftOpened: !state.data.layout.leftOpened
      }
    });
  })
  .handleAction(ACCOUNT_OPEN_NAV, (state: IAccountState) => {
    return loadedDataWrapper({
      ...state.data,
      layout: {
        ...state.data.layout,
        leftOpened: true
      }
    });
  })
  .handleAction(ACCOUNT_CLOSE_NAV, (state: IAccountState) => {
    return loadedDataWrapper({
      ...state.data,
      layout: {
        ...state.data.layout,
        leftOpened: false
      }
    });
  })
  .handleAction(ACCOUNT_TOGGLE_DETAILS, (state: IAccountState) => {
    return loadedDataWrapper({
      ...state.data,
      layout: {
        ...state.data.layout,
        rightOpened: !state.data.layout.rightOpened
      }
    });
  })
  .handleAction(ACCOUNT_OPEN_DETAILS, (state: IAccountState) => {
    return loadedDataWrapper({
      ...state.data,
      layout: {
        ...state.data.layout,
        rightOpened: true
      }
    });
  })
  .handleAction(ACCOUNT_CLOSE_DETAILS, (state: IAccountState) => {
    return loadedDataWrapper({
      ...state.data,
      layout: {
        ...state.data.layout,
        rightOpened: false
      }
    });
  })
  .handleAction(
    fetchPaymentRecommendedAmount.success,
    (state: IAccountState, action: PayloadAction<typeof ACCOUNT_RECOMMENDED_AMOUNT_SUCCESS, IRecommendedPayment>) =>
      loadedDataWrapper({
        ...state.data,
        recommendedPayment: action.payload
      })
  )
  .handleAction(
    fetchPaymentRecommendedAmount.failure,
    (state: IAccountState, action: PayloadAction<typeof ACCOUNT_RECOMMENDED_AMOUNT_FAILURE, Error>) =>
      errorDataWrapper(
        {
          ...state.data,
          recommendedPayment: null
        },
        action.payload
      )
  );
