import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

import FlrCard from 'components/shared/card/FlrCard';
import Icon from 'components/shared/Icon';
import { TextSubTitle, TitleH1 } from 'components/shared/text';
import { User } from 'models';
import { baseUrl } from 'shared/constants';
import messages from 'translations/account/dashboard';

import DashboardDeliverySkeleton from './DashboardDeliverySkeleton';

import classes from './DashboardDelivery.module.scss';

interface IProps {
  account: User | null;
}

const processOrdersSummary = (ordersSummary: {
  pending: number;
  preorders: number;
  processing: number;
  shipping: number;
}) => {
  const processedCount =
    (ordersSummary.pending || 0) + (ordersSummary.preorders || 0) + (ordersSummary.processing || 0);
  const deliveryCount = ordersSummary.shipping || 0;

  return [
    {
      icon: 'delivery',
      count: deliveryCount,
      label: messages.delivery.defaultMessage,
      id: 'delivery',
      link: 'status=delivery'
    },
    {
      icon: 'box',
      count: processedCount,
      label: messages.processed.defaultMessage,
      id: 'processed',
      link: 'status=new,approved'
    }
  ];
};

const DashboardDelivery: FC<IProps> = ({ account }) => {
  const ordersSummary = account?.profile.ordersSummary;
  const orderSummaryArray = ordersSummary ? processOrdersSummary(ordersSummary) : [];
  return (
    <div className={classes.deliveryContainer}>
      <div className={clsx(classes.title, classes.titleDelivery)}>
        <Icon className={classes.titleIcon} type={'delivery'} opacity={1} />
        <TitleH1 className={classes.titleCommon}>{messages.titleDelivery.defaultMessage}</TitleH1>
      </div>

      {Boolean(orderSummaryArray.length) ? (
        orderSummaryArray.map((item) => {
          const itemComponent = (
            <FlrCard hoverShadow={Boolean(item.count)} zeroPadding className={classes.cardLoyalty} key={item.id}>
              <div className={classes.cardLoyaltyContent}>
                <TextSubTitle className={classes.cardDeliveryLabel}>
                  <Icon type={item.icon} size={24} className={classes.cardDeliveryIcon} offset={8} opacity={1} />
                  <span className={classes.label}>{item.label}</span>
                </TextSubTitle>
                <TextSubTitle className={classes.orderCount}>{item.count}</TextSubTitle>
              </div>
            </FlrCard>
          );

          return (
            <div className={classes.wrapper} key={item.id}>
              <NavLink to={`${baseUrl}/account/orders?${item.link}`} className={classes.link}>
                {itemComponent}
              </NavLink>
            </div>
          );
        })
      ) : (
        <DashboardDeliverySkeleton />
      )}
    </div>
  );
};

export default DashboardDelivery;
