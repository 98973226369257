import { FC } from 'react';

import { GridDivider } from 'components/shared/table/dividers';
import { Link3, TextBody2 } from 'components/shared/text';
import { Transaction } from 'models/transaction';
import messages from 'translations/account/finance';

import { PAYMENT_TYPES_LABELS } from '../../../components/shared/modals/payment/PaymentModal';

import classes from './DetailsExpandPanel.module.scss';

interface IDetailsExpandPanelProps {
  data: Transaction;
}

const DetailsExpandPanel: FC<IDetailsExpandPanelProps> = ({ data }) => {
  const orderId = data.operation && data.operation.on ? data.operation.on.orderId : '';
  const paymentId = data.operation && data.operation.on ? data.operation.on.paymentId : '';

  return (
    <div className={classes.container}>
      {orderId ? (
        <>
          <div className={classes.expandedMobileRow}>
            <TextBody2 color="textSecondary">{messages.transactionNumber.defaultMessage}</TextBody2>
            <TextBody2 align="right">
              {data.operationType === 'debit' && data.operation.on.orderType !== 'Повернення' ? `№ ${paymentId}` : <Link3 to={`orders/${orderId}`}>№ {orderId}</Link3>}
            </TextBody2>
          </div>
          <GridDivider />
        </>
      ) : null}
      <div className={classes.expandedMobileRow}>
        <TextBody2 color="textSecondary">{messages.date.defaultMessage}</TextBody2>
        <TextBody2 color="textSecondary" align="right">
          {data.dateString}
        </TextBody2>
      </div>
      <GridDivider />
      <div className={classes.expandedMobileRow}>
        <TextBody2 color="textSecondary">
          {data.operation.onModel === 'Payment'
            ? messages.balanceModalPaymentType.defaultMessage
            : messages.typeOrder.defaultMessage}
        </TextBody2>
        <TextBody2 color="textSecondary" align="right">
          {data.operation.onModel === 'Order'
            ? data.operation.on.orderType
            : PAYMENT_TYPES_LABELS[data.operation.on.type]}
        </TextBody2>
      </div>
    </div>
  );
};

export default DetailsExpandPanel;
