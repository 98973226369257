import React from 'react';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';

import { Grid, Drawer, Button, ListItemText } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';

import { User } from 'models';

import AsideDeviceTopPanel from 'components/layout/aside-device/header';
import InCart from 'components/layout/header/InCart';
import { Balance } from 'components/layout/header/Balance';
import { Orders } from 'components/layout/header/Orders';
import { TitleH1 } from 'components/shared/text';
import Icon from 'components/shared/Icon';
import { GridDivider } from 'components/shared/table/dividers';

import { mobileTabs } from 'pages/account';
import { baseUrl } from 'shared/constants';
import messages from 'translations/account/menu';
import { currencies, languages } from '../top/HeaderTopSettings';
import { FlagIcon, FlagIconCode } from 'react-flag-kit';
import MobileMenuSubDrawer from './subdrawer';

export interface IProps {
  user: User;
  classes?: any;
  toggleDrawer: () => void;
  handleLogout: () => void;
}

const HeaderMobileMenu: React.FC<IProps> = ({ user, toggleDrawer, handleLogout, classes }) => {
  const balance = user ? user.profile.actualBalance : 0;
  const credit = user ? user.profile.credit : 0;
  const totalBalance = user ? user.profile.balance : 0
  const ordersSummary = user ? user.profile.ordersSummary : null;

  const [subDrawer, setSubDrawer] = React.useState<string | undefined>('');

  const openSubDrawer = (panel: string | undefined) => () => {
    setSubDrawer(panel);
  };
  const closeSubDrawer = () => {
    setSubDrawer('');
  };

  let subDrawerContent: null | React.ReactNode = null;

  if (subDrawer === 'locale') {
    subDrawerContent = (
      <MobileMenuSubDrawer
        title={messages.mobileMenuLanguageTitle.defaultMessage}
        items={languages}
        classes={classes}
        closeSubDrawer={closeSubDrawer}
      />
    );
  }
  if (subDrawer === 'currency') {
    subDrawerContent = (
      <MobileMenuSubDrawer
        title={messages.mobileMenuCurrencyTitle.defaultMessage}
        items={currencies}
        classes={classes}
        closeSubDrawer={closeSubDrawer}
      />
    );
  }

  return (
    <React.Fragment>
      <AsideDeviceTopPanel title={messages.title.defaultMessage} onClose={toggleDrawer} />

      <div className={classes.content}>
        <TitleH1 className={classes.mobileMenuTitle}>
          {user.profile.firstName} {user.profile.lastName}
        </TitleH1>

        <Grid container className={clsx(classes.mobileMenuTopLinks, 'mobile-menu-top-links')} spacing={3}>
          <Grid item onClick={toggleDrawer}>
            <Balance balance={balance} availableCredit={credit} totalBalance={totalBalance} />
          </Grid>

          <Grid item onClick={toggleDrawer}>
            <InCart />
          </Grid>

          <Grid item onClick={toggleDrawer}>
            <Orders ordersSummary={ordersSummary} />
          </Grid>
        </Grid>

        <GridDivider />

        <div className={classes.mainMenu}>
          {mobileTabs.map((content, key) => (
            <MenuItem key={key} className={classes.mainMenuItem} onClick={toggleDrawer}>
              <NavLink
                className={classes.mainMenuLink}
                to={`${baseUrl}/account/${content.path}`}
                activeClassName={'active'}
              >
                <Icon type={content.icon} size={24} offset={16} />
                <span>{content.label.defaultMessage}</span>
              </NavLink>
            </MenuItem>
          ))}
        </div>

        <GridDivider />

        <Grid container spacing={5} className={classes.mobileMenuSettings}>
          <Grid item>
            <Button variant={'text'} disabled onClick={openSubDrawer('location')}>
              Київ
            </Button>
          </Grid>
          <Grid item>
            <Button variant={'text'} onClick={openSubDrawer('locale')}>
              <FlagIcon code={'UA' as FlagIconCode} size={12} style={{ marginRight: 8 }} /> Українська
            </Button>
          </Grid>
          <Grid item>
            <Button variant={'text'} onClick={openSubDrawer('currency')}>
              ₴ грн
            </Button>
          </Grid>
        </Grid>

        <GridDivider />

        <MenuItem className={clsx(classes.mainMenuItem, classes.mainMenuItemLogOut)} onClick={toggleDrawer}>
          <Icon type={'signOut'} size={24} offset={8} />
          <ListItemText primary={messages.logout.defaultMessage} onClick={handleLogout} />
        </MenuItem>
      </div>

      <Drawer
        anchor={'bottom'}
        open={!!subDrawer}
        onClose={closeSubDrawer}
        classes={{
          paper: classes.subDrawerPaper
        }}
      >
        {subDrawerContent}
      </Drawer>
    </React.Fragment>
  );
};

export default HeaderMobileMenu;
