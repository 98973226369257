import { FC } from 'react';
import { useRouteMatch } from 'react-router';
import clsx from 'clsx';
import { Grid } from '@mui/material';

import { SplitedPrice } from 'components/prices';
import Icon from 'components/shared/Icon';
import { TextBody2, TextSubTitle } from 'components/shared/text';
import Tooltip from 'components/shared/tooltip';
import { baseUrl, UAH } from 'shared/constants';
import messages from 'translations/account/finance';
import menuMessages from 'translations/account/menu';

import { HeaderBottomLink } from './HeaderBottomLink';

import classes from './Header.module.scss';

interface ICartProps {
  balance: number;
  availableCredit: number;
  totalBalance: number;
}

const balanceUrl = `${baseUrl}/account/finance`;

const Balance: FC<ICartProps> = ({ balance, availableCredit, totalBalance }) => {
  const isLinkActive = useRouteMatch(balanceUrl);
  return (
    <div className={classes.wrapper}>
      <HeaderBottomLink
        to={balanceUrl}
        className={clsx({
          active: isLinkActive
        })}
      >
        <Icon type={'wallet'} className={'menu-cart-icon'} size={32} offset={8} withBackground opacity={1} />
        <Grid container className={classes.mainContent}>
          <Grid item xs={12}>
            <TextBody2 color={'textSecondary'} align={'inherit'}>
              {menuMessages.balance.defaultMessage}
            </TextBody2>
          </Grid>
          <Grid item>
            <Tooltip
              icon={<Icon type={'wallet'} size={24} offset={8} opacity={1} />}
              title={
                <div>
                  <TextSubTitle style={{ color: 'white', marginTop: 0, whiteSpace: 'normal' }}>Баланси</TextSubTitle>
                  <br />
                  <TextBody2 style={{ color: 'white', whiteSpace: 'normal' }}>
                    {messages.factBalanceValue.defaultMessage}: &nbsp;
                    <SplitedPrice fontSize={'body2'} value={balance} postfix={UAH} />
                  </TextBody2>
                  <br />
                  <TextBody2 style={{ color: 'white', whiteSpace: 'normal' }}>
                    {messages.credit.defaultMessage}: &nbsp;
                    <SplitedPrice fontSize={'body2'} value={availableCredit} postfix={UAH} />
                  </TextBody2>
                  <br />
                  <TextBody2 style={{ color: 'white', whiteSpace: 'normal' }}>
                    {messages.totalBalance.defaultMessage}: &nbsp;
                    <SplitedPrice fontSize={'body2'} value={totalBalance} postfix={UAH} />
                  </TextBody2>
                </div>
              }
            >
              <div>
                <SplitedPrice fontSize={'caption'} negative={balance < 0} value={balance} postfix={UAH} hideDecimal />
              </div>
            </Tooltip>
          </Grid>
        </Grid>
      </HeaderBottomLink>
    </div>
  );
};

export { Balance };
