import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Redirect } from 'react-router-dom';
import clsx from 'clsx';
import env, { isMobileDevice } from 'environment';
import moment from 'moment';
import { IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { FlrButtonKhaki, FlrButtonOutlinedBrown } from 'components/shared/buttons';
import FlrDatePicker from 'components/shared/form-elements/FlrDatePicker';
import { FlrTableV2 } from 'components/shared/table';
import { isReturnOrRefundTransaction, TransactionOperation, TransactionOperationType } from 'models/transaction';
import { fetchTransactionsAsync } from 'store/transactions/actions';
import {
  getTransactionsDataList,
  getTransactionsLoadingState,
  getTransactionsTotalDocs
} from 'store/transactions/selectors';
import messages from 'translations/account/finance';
import { HttpClient } from 'utils/http';

import { SelectMenu } from '../../../components/shared/select-menu/SelectMenu';
import { TextBody2 } from '../../../components/shared/text';
import { breadCrumbsContext } from '../breadCrumbsState';
import DetailsExpandPanel from './DetailsExpandPanel';
import FinanceTabs from './FinanceTabs';
import { SelectBalance } from './SelectBalance';
import TableColumns, { columnsMobile, dashboardColumns } from './TableColumns';

import classes from './Table.module.scss';

interface IProps {
  isDashboard?: boolean;
}

const allOption = { name: 'Всі', alias: 'all' };

const operationTypesOptions = [
  { name: 'Всі', alias: 'all' },
  { name: 'Поповнення', alias: 'debit' },
  { name: 'Оплата', alias: 'credit' }
];

const isMobile = Boolean(isMobileDevice(window.navigator));

const getActiveLabels = (
  activeItems: string[],
  allSelected: boolean,
  options: Array<{ name: string; alias: string }>,
  allName?: string
) => {
  if (activeItems?.length > 0 && !activeItems.includes('all')) {
    return options
      .map((item) => (activeItems.includes(item.alias) ? item.name : ''))
      .filter(Boolean)
      .join(', ');
  }

  return allSelected || activeItems.includes('all') ? allName : '';
};

const isAllSelected = (activeItems: string[]) => {
  return activeItems.length === 1 && activeItems[0] === 'all';
};

const Table: FC<IProps> = ({ isDashboard }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const transactions = useSelector(getTransactionsDataList);
  const transactionsLoadingState = useSelector(getTransactionsLoadingState);
  const totalDocs = useSelector(getTransactionsTotalDocs);

  const queryParams = new URLSearchParams(location.search);
  const activeOperationTypes = (queryParams.get('operationType') || '').split(',').filter(Boolean);
  const isAllSelectedOperationType = isAllSelected(activeOperationTypes);
  const [anchorOperationTypeEl, setAnchorOperationTypeEl] = useState<null | HTMLElement>(null);
  const [openOperationTypeMenu, setOpenOperationTypeMenu] = useState(false);

  useEffect(() => {
    queryParams.set('includeCurrency', 'true');

    dispatch(fetchTransactionsAsync.request(`?${queryParams.toString()}`));
  }, [location.search]);

  /*start from week ago*/
  const [dateXlsFrom, setDateXlsFrom] = useState<Date | null>(new Date(Date.now() - 60 * 60 * 24 * 7 * 1000));
  const [dateXlsTo, setDateXlsTo] = useState<Date | null>(new Date());
  const handleDateChange =
    (isTo = false) =>
    (date: any) => {
      if (isTo) {
        return setDateXlsTo(date);
      }
      setDateXlsFrom(date);
    };

  const [modalOpen, setModalOpen] = useState(false);
  const handleCancel = () => {
    setModalOpen(false);
  };
  const handleModalConfirm = () => {
    handleDownloadPDF();
    setModalOpen(false);
  };

  const handleOpenOperationTypeMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorOperationTypeEl(event.currentTarget);
    setOpenOperationTypeMenu(true);
  };

  const handleCloseOperationTypeMenu = () => {
    setOpenOperationTypeMenu(false);
  };

  const redirectToUrl = (type: string, value: string) => {
    if (value === '0') {
      queryParams.delete(type);
    } else {
      queryParams.set(type, value);
    }
    queryParams.delete('page');
    history.push(`${location.pathname}?${decodeURIComponent(queryParams.toString())}`);
  };

  const handleMenuItemClickOperationTypeMenu = (alias: string, checked: boolean) => {
    handleCloseOperationTypeMenu();

    let updatedTypes;
    if (alias === 'all' && checked) {
      updatedTypes = [alias];
    } else if (checked) {
      updatedTypes =
        isAllSelectedOperationType || alias === 'all'
          ? [alias]
          : Array.from(new Set([...activeOperationTypes, alias])).filter((s) => s !== 'all');
    } else {
      updatedTypes = activeOperationTypes.filter((type) => type !== alias);
    }
    redirectToUrl('operationType', updatedTypes.join(','));
  };

  const handleDownloadPDF = () => {
    const url = `${env.apiUrl}/account/transactions/exportToCsv`;
    const httpClient = new HttpClient(url);
    const token = window.localStorage.getItem('token') || '';
    const headers = { Authorization: token };

    httpClient
      .get('', {
        params: {
          startDate: dateXlsFrom,
          endDate: dateXlsTo
        },
        responseType: 'blob',
        headers
      })
      .then((response) => {
        downloadFile(response.data, 'transactions.xlsx');
      })
      .catch((err) => {
        // todo show toast
      });
  };

  transactions.forEach((txnItem) => {
    let transactionOperation = txnItem?.operation?.onModel;
    if (isReturnOrRefundTransaction(txnItem)) {
      switch (txnItem?.operationType) {
        case TransactionOperationType.debit:
          transactionOperation = TransactionOperation.Reclamation;
          break;
        case TransactionOperationType.credit:
          transactionOperation = TransactionOperation.Refund;
          break;
      }
    }
    txnItem.transactionOperation = transactionOperation;
  });

  const { setCurrentUrl } = useContext(breadCrumbsContext);
  const updateBreadCrumbs = useCallback(() => {
    setCurrentUrl([{ label: messages.title.defaultMessage }]);
  }, [setCurrentUrl]);

  useEffect(() => {
    updateBreadCrumbs();
  }, [updateBreadCrumbs]);

  useEffect(() => {
    updateBreadCrumbs();

    return () => {
      setCurrentUrl([]);
    };
  }, [setCurrentUrl, updateBreadCrumbs]);

  /** redirect from root path */
  if (!activeOperationTypes?.length) {
    return <Redirect to={`${location.pathname}?operationType=${allOption.alias}`} />;
  }

  const tableCommonProps = {
    totalDocs,
    data: transactions,
    isLoadingExternal: transactionsLoadingState,
    hidePagination: isDashboard || totalDocs < 10,
    isDashboard,
    isFinance: true,
    options: {
      actionsColumnIndex: -1,
      headerStyle: {
        maxWidth: 'fill-available'
      }
    }
  };

  const columns = isMobile
    ? columnsMobile
    : !isDashboard
    ? TableColumns(queryParams.has('completedOnly'))
    : dashboardColumns;

  const activeOperationTypesLabels = getActiveLabels(
    activeOperationTypes,
    isAllSelectedOperationType,
    operationTypesOptions,
    allOption.name
  );

  return (
    <>
      {/*{!isDashboard ? (*/}
      {/*  <div className={classes.tableToolbarContainer}>*/}
      {/*    */}
      {/*    TODO: uncomment after fixed XLSX export*/}
      {/*    <div className={classes.filterContainer}>*/}
      {/*      <Link2 className={classes.downloadButton} onClick={handleOpenModal}>*/}
      {/*        <Icon type={'attach'} offset={8} size={16} />*/}
      {/*        <span>{messages.transactionDownloadXLS.defaultMessage}</span>*/}
      {/*      </Link2>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*) : null}*/}
      {!isDashboard ? (
        <>
          <FinanceTabs classNameWrapper={classes.tableToolbarContainer} />
          <div className={classes.actionContainer}>
            <IconButton
              size="large"
              onClick={() => redirectToUrl('completedOnly', '0')}
              className={clsx(classes.actionButton, {
                [classes.activeActionButton]: !queryParams.has('completedOnly')
              })}
            >
              <TextBody2 component="span">Загальний баланс</TextBody2>
            </IconButton>
            <IconButton
              size="large"
              onClick={() => redirectToUrl('completedOnly', '1')}
              className={clsx(classes.actionButton, { [classes.activeActionButton]: queryParams.has('completedOnly') })}
            >
              <TextBody2 component="span">Фактичний баланс</TextBody2>
            </IconButton>
            <SelectBalance />
            <SelectMenu
              label={'Тип операції'}
              options={operationTypesOptions}
              activeItems={activeOperationTypes}
              allSelected={isAllSelectedOperationType}
              anchorEl={anchorOperationTypeEl}
              openMenu={openOperationTypeMenu}
              handleOpenMenu={handleOpenOperationTypeMenu}
              handleCloseMenu={handleCloseOperationTypeMenu}
              handleMenuItemClick={handleMenuItemClickOperationTypeMenu}
              activeLabels={activeOperationTypesLabels}
              withoutQty
            />
          </div>
        </>
      ) : null}
      <FlrTableV2
        {...tableCommonProps}
        detailPanel={isMobile ? DetailsExpandPanel : null}
        columns={columns}
        classNameWrapper={classes.tableContentWrapper}
      />
      <Dialog
        open={modalOpen}
        onClose={handleCancel}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{messages.downloadModalTitle.defaultMessage}</DialogTitle>
        <DialogContent dividers={true}>
          <p>{messages.downloadModalCaption.defaultMessage}</p>
          <div className={classes.downloadForm}>
            <FlrDatePicker
              id="date-from"
              maxDate={moment(dateXlsTo)}
              label={messages.downloadDateFrom.defaultMessage}
              value={moment(dateXlsFrom)}
              onChange={handleDateChange()}
            />
            <FlrDatePicker
              id="date-to"
              disableFuture
              label={messages.downloadDateTo.defaultMessage}
              value={moment(dateXlsTo)}
              minDate={moment(dateXlsFrom)}
              onChange={handleDateChange(true)}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <FlrButtonOutlinedBrown onClick={handleCancel} color="primary">
            {messages.cancelBtnLabel.defaultMessage}
          </FlrButtonOutlinedBrown>
          <FlrButtonKhaki onClick={handleModalConfirm} color="primary">
            {messages.downloadModalButton.defaultMessage}
          </FlrButtonKhaki>
        </DialogActions>
      </Dialog>
    </>
  );
};

function downloadFile(data: any, fileName: string): void {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName || 'file.pdf');
  document.body.appendChild(link);
  link.click();
}

export default Table;
