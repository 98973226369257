import { FC } from 'react';
import { connect, MapStateToProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { TitleH1 } from 'components/shared/text';
import { useTrackInitialPageLoad } from 'controllers';
import { User } from 'models';
import { getAccountLoadingState, getUserAccount } from 'store/account/selectors';
import { IApplicationState } from 'store/reducers';
import messages from 'translations/account/finance';

import FinanceInfo from './FinanceInfo';
import TransactionsTable from './Table';

import classes from './Finance.module.scss';

interface IAccountFinanceProps {
  account: User;
  accountLoadingState: boolean;
}

const AccountFinance: FC<IAccountFinanceProps> = ({ account, accountLoadingState }) => {
  useTrackInitialPageLoad();

  if (accountLoadingState) {
    return null;
  }
  return (
    <>
      <div className={classes.container}>
        <div className={classes.wrapper}>
          <TitleH1 className={classes.title}>{messages.title.defaultMessage}</TitleH1>
          <TransactionsTable />
        </div>
        <FinanceInfo account={account} />
      </div>
    </>
  );
};

const mapStateToProps: MapStateToProps<{}, {}, IApplicationState> = (state: IApplicationState) => ({
  account: getUserAccount(state),
  accountLoadingState: getAccountLoadingState(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators({}, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountFinance);
