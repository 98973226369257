import { useState } from 'react';
import { useSelector } from 'react-redux';
import { isMobileDevice } from 'environment';

import { SplitedPrice } from 'components/prices';
import { Icon } from 'components/shared';
import { FlrButtonKhaki } from 'components/shared/buttons';
import FlrCard from 'components/shared/card/FlrCard';
import PaymentModal from 'components/shared/modals/payment/PaymentModal';
import { getUserAccount } from 'store/account/selectors';
import messages from 'translations/account/dashboard';

import classes from './DashboardFinance.module.scss';

const isMobile = isMobileDevice(window.navigator);
const DashboardFinance = () => {
  const account = useSelector(getUserAccount);
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  if (!account) {
    return null;
  }

  const handlePayCancel = () => {
    setPaymentModalOpen(false);
  };
  const handleOpenPayModal = () => {
    setPaymentModalOpen(true);
  };
  const actualBalance = account && account.profile.actualBalance;
  const balance = account && account.profile.balance;
  const credit = account && account.profile.credit;

  return (
    <div className={classes.container}>
      <div className={classes.titleContainer}>
        <Icon type="finance" size={32} offset={16} opacity={1} />
        <h3 className={classes.title}>Фінанси</h3>
      </div>
      <FlrCard hoverShadow className={classes.card}>
        <div className={classes.titleContainer}>
          <span className={classes.cardTitle}>Баланси</span>
          <FlrButtonKhaki color="primary" className={classes.button} onClick={handleOpenPayModal}>
            Поповнити
          </FlrButtonKhaki>
        </div>
        <div className={classes.balanceContainer}>
          <>
            <span className={classes.factBalanceLabel}>
              {isMobile ? messages.factBalanceMobile.defaultMessage : messages.factBalance.defaultMessage}
            </span>
            <SplitedPrice
              value={account?.profile.actualBalance}
              negative={account?.profile.actualBalance < 0}
              className={classes.factBalanceValue}
            />
          </>
          <div className={classes.creditContainer}>
            <span className={classes.balanceItemSubTitle}>{messages.credit.defaultMessage}</span>
            <SplitedPrice value={credit} className={classes.splitedPriceItem} />
          </div>
        </div>
        <div className={classes.totalBalanceContainer}>
          <div className={classes.itemTotalBalance}>
            <span className={classes.factBalanceLabel}>{messages.totalBalance.defaultMessage}</span>
            <SplitedPrice value={balance} className={classes.factBalanceValue} />
          </div>
          <div className={classes.itemTotalBalance}>
            <span className={classes.balanceItemSubTitle}>{messages.factBalanceValue.defaultMessage}</span>
            <SplitedPrice value={actualBalance} className={classes.splitedPriceItem} />
          </div>
          <div className={classes.itemTotalBalance}>
            <span className={classes.balanceItemSubTitle}>{messages.orderInPending.defaultMessage}</span>
            <SplitedPrice
              value={-1 * (account?.profile?.operatingOrdersTotalSum || actualBalance - balance)}
              className={classes.splitedPriceItem}
            />
          </div>
        </div>
      </FlrCard>
      {paymentModalOpen && (
        <PaymentModal open={paymentModalOpen} handleClose={handlePayCancel} amount={actualBalance} />
      )}
    </div>
  );
};

export { DashboardFinance };
