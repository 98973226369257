import { FC, MouseEvent, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import clsx from 'clsx';
import { iOS } from 'environment';
import { TableContainer } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import makeStyles from '@mui/styles/makeStyles';

import FlrLoader from 'components/loading/LoadingSpinner';
import { baseUrl } from 'shared/constants';
import messages from 'translations/layout/table';

import { SelectBalance } from '../../../pages/account/finance/SelectBalance';
import FlrCard from '../card/FlrCard';
import Link2 from '../text/Link2';
import { DetailPanelWrapper } from './DetailPanelWrapper';
import { EnhancedTableHead } from './EnhancedTableHead';
import { PaginationTable } from './pagination/PaginationTable';
import styles from './styles';

const isIOS = iOS(window.navigator.userAgent);
const useStyles = makeStyles(styles);

interface IProps {
  data: any[];
  columns: any[];
  isLoadingExternal?: boolean;
  detailPanel?: any;
  components?: any;
  hidePagination?: boolean;
  classNameWrapper?: string;
  totalDocs: number;
  isDashboard?: boolean;
  isFinance?: boolean;
}

const ROW_PER_PAGE = 10;

type SortOrder = 'asc' | 'desc';

const FlrTableV2: FC<IProps> = ({
  data,
  columns,
  // components = {},
  isLoadingExternal,
  detailPanel,
  hidePagination,
  classNameWrapper,
  totalDocs,
  isDashboard,
  isFinance
}) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);

  const order = (params.get('sortOrder') as SortOrder) || 'asc';
  const orderBy = params.get('sortField') || '';
  const pageParam = params.get('page');
  const page = pageParam ? parseInt(pageParam, 10) : 1;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [expandedRow, setExpandedRow] = useState<number | null>(null);

  const updateURL = (sortField: string, sortOrder: SortOrder) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('sortField', sortField);
    searchParams.set('sortOrder', sortOrder);
    const query = decodeURIComponent(searchParams.toString());
    history.push({ search: query });
  };

  const handleRequestSort = (event: MouseEvent<unknown>, property: any) => {
    const column = columns.find((col) => col.field === property);
    if (!column || column.sorting === false) {
      return;
    }

    const isAsc = orderBy === property && order === 'asc';
    const nextSortOrder = isAsc ? 'desc' : 'asc';
    updateURL(property, nextSortOrder);
  };

  const handleScroll = () => {
    setIsOpen(false);
  };

  // * close "select per row" dropdown while scrolling
  useEffect(() => {
    if (isOpen) {
      window.addEventListener('scroll', handleScroll);
    } else {
      window.removeEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isOpen]);

  const handleClick = (index: number) => {
    if (!detailPanel) {
      return;
    }

    if (index !== expandedRow) {
      setExpandedRow(index);
    } else if (typeof expandedRow === 'number') {
      setExpandedRow(null);
    } else {
      setExpandedRow(index);
    }
  };
  return (
    <>
      <FlrCard classes={{ root: clsx(classes.card, classNameWrapper) }}>
        {isDashboard ? (
          <>
            <div className={classes.titleContainer}>
              <span className={classes.title}>Останні операції</span>
              <Link2 to={`${baseUrl}/account/finance`}>Всі операції</Link2>
            </div>
            <SelectBalance isDashboard={isDashboard} />
          </>
        ) : null}
        <TableContainer className={clsx(classes.tableContainer, { classNameWrapper })}>
          <Table>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={columns}
              isDashboard={isDashboard}
            />
            <TableBody>
              {!data || !data.length ? (
                <TableRow>
                  <TableCell colSpan={columns.length} style={{ textAlign: 'center' }}>
                    {messages.emptyTable.defaultMessage}
                  </TableCell>
                </TableRow>
              ) : null}
              {data && data.length
                ? data.map((row: any, index: number) => (
                    <>
                      <TableRow
                        className={clsx({
                          [classes.expandedRow]: !isIOS && detailPanel && expandedRow === index
                        })}
                        onClick={() => handleClick(index)}
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                      >
                        {columns.map((c: any) => (
                          <TableCell
                            align={c.type === 'numeric' ? 'right' : 'left'}
                            key={c.field}
                            className={clsx(classes.tableCell, {
                              [classes.dashboardTableCell]: isDashboard,
                              [classes.financeTableCell]: isFinance
                            })}
                          >
                            {c.render(row)}
                          </TableCell>
                        ))}
                        {isIOS && detailPanel && expandedRow === index ? (
                          <div className={classes.expandedRowIOS} />
                        ) : null}
                      </TableRow>
                      {detailPanel ? (
                        <TableRow>
                          <TableCell style={{ padding: 0, borderBottom: 'none' }} colSpan={columns.length}>
                            <Collapse in={expandedRow === index} timeout={0} unmountOnExit>
                              <DetailPanelWrapper data={row} children={detailPanel} />
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </>
                  ))
                : null}
            </TableBody>
          </Table>
          {isLoadingExternal && <FlrLoader withOverlay={true} />}
        </TableContainer>
      </FlrCard>
      {!hidePagination && <PaginationTable count={totalDocs} page={page} rowsPerPage={ROW_PER_PAGE} />}
    </>
  );
};

export { FlrTableV2 };
