import React, { FC, useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import { Link } from 'react-router-dom';
import { baseUrl } from '../../../shared/constants';

const rootPath = `/account/finance`;

interface ITab {
  key: string;
  path: string;
  label: string;
}

const paths: ITab[] = [
  {
    key: 'all',
    path: `${rootPath}`,
    label: 'Операції',
  }
];

interface IFinanceTabsProps {
  classNameWrapper?: string;
}

const FinanceTabs:FC<IFinanceTabsProps> = ({classNameWrapper}) => {
  const [tab, setTab] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  return (
    <div className={classNameWrapper}>
      <Tabs
        value={tab}
        onChange={handleChange}
        indicatorColor={'primary'}
        variant="standard"
        scrollButtons={false}
      >
        {paths.map((tabItem) => {
          return (
            <Tab
              key={tabItem.path}
              label={tabItem.label}
              component={Link}
              to={`${baseUrl}${tabItem.path}`}
            />
          );
        })}
      </Tabs>
    </div>
  );
};

export default FinanceTabs;