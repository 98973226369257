import { FC, useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { Button, useMediaQuery, useTheme } from '@mui/material';

import { SplitedPrice } from 'components/prices';
import { ExpandPanel } from 'components/shared';
import FlrCardNarrow from 'components/shared/card/FlrCardNarrow';
import { IBalance } from 'models/account-discounts';
import messages from 'translations/account/loyalty';
import { formatNumber } from 'utils/helpers';

import PersonalDiscountProgressCard from '../cards/PersonalDiscountProgressCard';

import classes from './Deposit.module.scss';

interface IDepositProps {
  onClick: () => void;
  progressValue: any;
  balance?: IBalance;
  actualBalance: number | null;
}

const Deposit: FC<IDepositProps> = ({ onClick, progressValue, balance, actualBalance }) => {
  const theme = useTheme();
  const screenMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isExpanded, setIsExpanded] = useState(false);
  if (!balance) {
    return null;
  }
  const maxConditionValue = Math.max(...Object.keys(balance.conditions).map(Number));
  const formatDateWithWeekday = (inputDate: string, isNextWeek?: boolean): string => {
    let date = moment(inputDate).add(1, 'minute');
    if (isNextWeek) {
      date = date.add(1, 'week');
    }
    return date.format('dd, DD.MM.YY HH:mm');
  };
  return (
    <div className={classes.container}>
      <FlrCardNarrow hoverShadow className={classes.card}>
        <div className={classes.titleContainer}>
          <h3 className={classes.title}>{messages.depositTitle.defaultMessage}</h3>
          <Button variant="contained" onClick={onClick} className={clsx(classes.button, classes.desktop)}>
            Поповнити
          </Button>
        </div>
        <div className={clsx(classes.content, { [classes.contentExpanded]: isExpanded })}>
          <div className={classes.contentItem}>
            <div className={classes.factDepositContainer}>
              <div className={classes.item}>
                <span className={classes.titleItem}>{messages.factDeposit.defaultMessage}</span>
                {actualBalance ? (
                  <SplitedPrice value={actualBalance} negative={actualBalance < 0} className={classes.factDeposit} />
                ) : null}
              </div>
              {/*TODO: wait back-end*/}
              {/*<div className={classes.factDepositItem}>*/}
              {/*  <span className={classes.factDepositLabel}>{messages.ownDeposit.defaultMessage}</span>*/}
              {/*  <SplitedPrice value={0} className={classes.factDepositValue} />*/}
              {/*</div>*/}
              {/*<div className={classes.factDepositItem}>*/}
              {/*  <span className={classes.factDepositLabel}>{messages.finishedOrders.defaultMessage}</span>*/}
              {/*  <SplitedPrice value={hardcodeBalance} className={classes.factDepositValue} />*/}
              {/*</div>*/}
            </div>
            <Button variant="contained" onClick={onClick} className={classes.mobile}>
              Поповнити
            </Button>
          </div>
          <div className={classes.contentItem}>
            <div className={classes.currentWeekContainer}>
              <div className={clsx(classes.wrapper, classes.currentWeekLabel)}>
                <span className={clsx(classes.titleItem, classes.name, classes.currentWeekLabel)}>
                  {messages.currentWeek.defaultMessage}
                </span>
                <span className={clsx(classes.discountValue, classes.onlyMobile)}>
                  {balance?.currentDiscount?.percent}%
                </span>
              </div>
              <span className={classes.fixedDiscount}>
                {messages.discountFixed.defaultMessage}&nbsp;{formatDateWithWeekday(balance?.history[0].onDate)}
              </span>
              <div className={classes.item}>
                <span className={classes.discountMessage}>{messages.discountMessage.defaultMessage}</span>
                <span className={clsx(classes.discountValue, classes.desktop)}>
                  {balance?.currentDiscount?.percent}%
                </span>
              </div>
            </div>
          </div>
          <div className={classes.contentItem}>
            {screenMobile ? (
              <ExpandPanel
                expanded={isExpanded}
                onChange={() => setIsExpanded(!isExpanded)}
                leftIcon
                classNameSummary={classes.summary}
                label={
                  <div className={classes.expandWrapper}>
                    <div className={classes.wrapper}>
                      <span className={clsx(classes.titleItem, classes.name, classes.nextWeekLabel)}>
                        {messages.nextWeek.defaultMessage}
                      </span>
                      <span className={classes.estimatedDiscountValue}>{balance?.nextDiscountProgress?.percent}%</span>
                    </div>
                    <span className={classes.discountFixedEstimated}>{messages.estimatedDiscount.defaultMessage}</span>
                  </div>
                }
              >
                <div className={clsx(classes.item, classes.spacing)}>
                  <span className={classes.discountMessage}>{messages.currentDeposit.defaultMessage}</span>
                  <span className={classes.currentDepositValue}>
                    {formatNumber(balance?.nextDiscountProgress?.progressValue)}
                  </span>
                </div>
                {maxConditionValue > balance.nextDiscountProgress.percent ? (
                  <div className={classes.item}>
                    <span className={classes.depositForDiscount}>
                      {messages.depositForDiscount.defaultMessage} {balance?.nextDiscountProgress?.percent}%
                    </span>
                    <span className={classes.depositForDiscount}>
                      {formatNumber(balance?.nextDiscountProgress?.requiredValue)}
                    </span>
                  </div>
                ) : null}
                <PersonalDiscountProgressCard progressValue={progressValue} classes={classes} />
              </ExpandPanel>
            ) : (
              <span className={clsx(classes.titleItem, classes.name)}>{messages.nextWeek.defaultMessage}</span>
            )}
            {!screenMobile ? (
              <>
                <span className={classes.fixedDiscount}>
                  {messages.discountFixedEstimated.defaultMessage}&nbsp;
                  {formatDateWithWeekday(balance?.history[0].onDate, true)}
                </span>
                <div className={clsx(classes.item, classes.largeSpacing)}>
                  <span className={classes.estimatedDiscountLabel}>{messages.estimatedDiscount.defaultMessage}</span>
                  <span className={clsx(classes.estimatedDiscountValue, classes.desktop)}>
                    {balance?.nextDiscountProgress?.percent}%
                  </span>
                </div>
                <div className={clsx(classes.item, classes.spacing)}>
                  <span className={classes.currentDepositLabel}>{messages.currentDeposit.defaultMessage}</span>
                  <span className={classes.currentDepositValue}>
                    {formatNumber(balance?.nextDiscountProgress?.progressValue)}
                  </span>
                </div>
                {maxConditionValue > balance.nextDiscountProgress.percent ? (
                  <div className={classes.item}>
                    <span className={classes.depositForDiscount}>
                      {messages.depositForDiscount.defaultMessage}&nbsp;{balance?.nextDiscountProgress?.percent}%
                    </span>
                    <span className={classes.depositForDiscount}>
                      {formatNumber(balance?.nextDiscountProgress?.requiredValue)}
                    </span>
                  </div>
                ) : null}
                <PersonalDiscountProgressCard progressValue={progressValue} />
              </>
            ) : null}
          </div>
        </div>
      </FlrCardNarrow>
    </div>
  );
};

export { Deposit };
