import { useSelector } from 'react-redux';
import { isMobileDevice } from 'environment';

import { Icon } from 'components/shared';
import { getAccountDiscountsData } from 'store/account-discounts/selectors';
import messages from 'translations/account/dashboard';

import { DashboardLoyaltyProgramCard } from './DashboardLoyaltyProgramCard';
import { DashboardLoyaltyProgramList } from './DashboardLoyaltyProgramList';

import classes from './DashboardLoyaltyProgram.module.scss';

const isMobile = isMobileDevice(window.navigator);

const DashboardLoyaltyProgram = () => {
  const accountDiscount = useSelector(getAccountDiscountsData);
  if (!accountDiscount || !accountDiscount.balance?.[0]) {
    return null;
  }

  return (
    <>
      <div className={classes.titleContainer}>
        <Icon type="loyalty" size={32} opacity={1} />
        <div className={classes.titleWrapper}>
          <h3 className={classes.title}>
            {isMobile ? messages.loyaltyTitleMobile.defaultMessage : messages.loyaltyTitleDesktop.defaultMessage}
          </h3>
          <p className={classes.subTitle}>Знижки сумуються</p>
        </div>
      </div>
      <DashboardLoyaltyProgramCard balance={accountDiscount?.balance[0]} />
      <DashboardLoyaltyProgramList purchasedQty={accountDiscount['purchased-qty']} />
      {accountDiscount.permanentDiscounts.map((item, index) => (
        <DashboardLoyaltyProgramCard
          title={item.name}
          description={item.description}
          percent={Number(Object.keys(item.conditions)[0])}
          linkTitle="До каталогу"
          hasLink
          key={index}
        />
      ))}
    </>
  );
};

export { DashboardLoyaltyProgram };
